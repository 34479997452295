import { Modal } from '@ui';
import React from 'react';
import { SessionForm } from './SessionForm';

export const Auth = () => {
	return (
		<Modal
			isOpen={true}
			title={'Share security'}
			closeButton={false}
			shouldCloseOnOverlayClick={false}
			onClose={undefined}
			shouldCloseOnEsc={undefined}
			customClassNameContent={'h-36 w-[296px]'}
			paddingContent={'px-4 py-2'}
		>
			<SessionForm />
		</Modal>
	);
};
