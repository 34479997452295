import { useMediaQuery } from 'react-responsive';

/**
 * Check the device is mobile or not
 * @returns boolean
 */
export const useDetectMobile = () => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });
	return isTabletOrMobile;
};
