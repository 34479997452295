import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const style = 'whitespace-nowrap text-xs text-common-light font-normal';

const WindowLevelMenuItem = ({ title, subtitle, isSelected, index, isVisibleShortcutKey }) => {
	return (
		<>
			<div
				className={classNames(
					'flex h-7 w-full flex-row items-center bg-primary-dark px-2 hover:bg-primary-light',
					isSelected && 'bg-primary-light'
				)}
			>
				<span className={`mr-2 ${style}`}>{title}</span>
				<span className={`flex-1 ${style}`}>{subtitle && `(${subtitle})`}</span>
				{!isNaN(index) && isVisibleShortcutKey ? <span className={`ml-5 ${style}`}>{`(${index + 1})`}</span> : <></>}
			</div>
		</>
	);
};

WindowLevelMenuItem.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	isSelected: PropTypes.bool,
	index: PropTypes.number,
	isVisibleShortcutKey: PropTypes.bool
};

export default WindowLevelMenuItem;
