import React from 'react';
import QRScanButton from '../QRScanModal/QRScanButton';
import ToolbarButton from '../ToolbarButton';
import AboutButton from '../AboutModal/AboutButton';
import ShareLinkButton from '../ShareLinkModal/ShareLinkButton';

export const AddOnButtons = () => {
	return (
		<div
			className="flex items-center"
			id="add-on"
		>
			{/* <span className="mr-3 text-lg text-common-light">{t('INVESTIGATIONAL USE ONLY')}</span> */}
			<QRScanButton />
			<ShareLinkButton />
			<ToolbarButton
				icon={'expand'}
				label={'Full screen'}
				onInteraction={() => {}}
				id="expand"
				type="action"
				className="cursor-not-allowed"
			/>
			<AboutButton />
		</div>
	);
};
