import React from 'react';

export const SavePresetButton = ({ onSave }: { onSave: () => void }) => {
	return (
		<div className="flex flex-row-reverse">
			<button
				className="w-20 rounded-[2px] border border-primary-light bg-primary-light text-sm text-common-light hover:border-primary-active hover:bg-primary-active"
				onClick={() => onSave()}
			>
				Save
			</button>
		</div>
	);
};
