// @ts-nocheck

import { DicomMetadataStore, MODULE_TYPES } from '@core';
import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Dropzone from 'react-dropzone';
import filesToStudies from './filesToStudies';

import { extensionManager } from '../../App';

import { Button, Icon, LoadingIndicatorProgress } from '@ui';

const getLoadButton = (onDrop, text, isDir) => {
	return (
		<Dropzone
			onDrop={onDrop}
			noDrag
		>
			{({ getRootProps, getInputProps }) => (
				<div {...getRootProps()}>
					<Button
						rounded="full"
						variant="contained" // outlined
						disabled={false}
						endIcon={<Icon name="launch-arrow" />} // launch-arrow | launch-info
						className={classnames('font-medium', 'ml-2')}
						onClick={() => {}}
					>
						{text}
						{isDir ? (
							<input
								{...getInputProps()}
								webkitdirectory="true"
								mozdirectory="true"
							/>
						) : (
							<input {...getInputProps()} />
						)}
					</Button>
				</div>
			)}
		</Dropzone>
	);
};

type LocalProps = {
	modePath: string;
};

function Local({ modePath }: LocalProps) {
	const navigate = useNavigate();
	const dropzoneRef = useRef();
	const [dropInitiated, setDropInitiated] = React.useState(false);

	// Initializing the dicom local dataSource
	const dataSourceModules = extensionManager.modules[MODULE_TYPES.DATA_SOURCE];
	const localDataSources = dataSourceModules.reduce((acc, curr) => {
		const mods = [];
		curr.module.forEach((mod) => {
			if (mod.type === 'localApi') {
				mods.push(mod);
			}
		});
		return acc.concat(mods);
	}, []);

	const firstLocalDataSource = localDataSources[0];
	const dataSource = firstLocalDataSource.createDataSource({});

	const microscopyExtensionLoaded = extensionManager.registeredExtensionIds.includes('@extensions/dicom-microscopy');

	const onDrop = async (acceptedFiles) => {
		const studies = await filesToStudies(acceptedFiles, dataSource);

		const query = new URLSearchParams();

		if (microscopyExtensionLoaded) {
			// TODO: for microscopy, we are forcing microscopy mode, which is not ideal.
			//     we should make the local drag and drop navigate to the worklist and
			//     there user can select microscopy mode
			const smStudies = studies.filter((id) => {
				const study = DicomMetadataStore.getStudy(id);
				return study.series.findIndex((s) => s.Modality === 'SM' || s.instances[0].Modality === 'SM') >= 0;
			});

			if (smStudies.length > 0) {
				smStudies.forEach((id) => query.append('StudyInstanceUIDs', id));

				modePath = 'microscopy';
			}
		}

		// Todo: navigate to work list and let user select a mode
		studies.forEach((id) => query.append('StudyInstanceUIDs', id));
		query.append('datasources', 'dicomlocal');

		navigate(`/${modePath}?${decodeURIComponent(query.toString())}`);
	};

	// Set body style
	useEffect(() => {
		document.body.classList.add('bg-black');
		return () => {
			document.body.classList.remove('bg-black');
		};
	}, []);

	return (
		<Dropzone
			ref={dropzoneRef}
			onDrop={(acceptedFiles) => {
				setDropInitiated(true);
				onDrop(acceptedFiles);
			}}
			noClick
		>
			{({ getRootProps }) => (
				<div
					{...getRootProps()}
					style={{ width: '100%', height: '100%' }}
				>
					<div className="flex h-screen w-screen items-center justify-center ">
						<div className="mx-auto space-y-2 rounded-lg bg-secondary-dark px-8 py-8 drop-shadow-md">
							<img
								className="mx-auto block h-14"
								src="./ohif-logo.svg"
								alt="OHIF"
							/>
							<div className="space-y-2 pt-4 text-center">
								{dropInitiated ? (
									<div className="flex flex-col items-center justify-center pt-48">
										<LoadingIndicatorProgress className={'h-full w-full bg-black'} />
									</div>
								) : (
									<div className="space-y-2">
										<p className="text-base text-blue-300">
											Note: You data is not uploaded to any server, it will stay in your local browser application
										</p>
										<p className="text-xg pt-6 font-semibold text-primary-active">
											Drag and Drop DICOM files here to load them in the Viewer
										</p>
										<p className="text-lg text-blue-300">Or click to </p>
									</div>
								)}
							</div>
							<div className="flex justify-around pt-4 ">
								{getLoadButton(onDrop, 'Load files', false)}
								{getLoadButton(onDrop, 'Load folders', true)}
							</div>
						</div>
					</div>
				</div>
			)}
		</Dropzone>
	);
}

export default Local;
