import React from 'react';
import QRCode from 'react-qr-code';
const QRScanModal = () => {
	const currentURL = window.location.href;

	return (
		<QRCode
			size={200}
			value={currentURL}
		/>
	);
};

export default QRScanModal;
