import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import classNames from 'classnames';
import * as Types from '../../types';
import Thumbnail from '../Thumbnail';
import ThumbnailNoImage from '../ThumbnailNoImage';
import ThumbnailTracked from '../ThumbnailTracked';

const thumbnailListClasses = {
	default: 'overflow-x-hidden',
	onSubPanel: 'grid grid-cols-2 gap-1',
	onMobile: 'flex w-full flex-row overflow-x-auto'
};

const ThumbnailList = ({
	thumbnails,
	onThumbnailClick,
	onThumbnailDoubleClick,
	onClickUntrack,
	activeDisplaySetInstanceUIDs = [],
	servicesManager,
	onSubPanel = false,
	onMobile = false
}) => {
	const styles = useMemo(() => {
		if (onMobile) {
			return thumbnailListClasses['onMobile'];
		}
		if (onSubPanel) {
			return thumbnailListClasses['onSubPanel'];
		}
		return thumbnailListClasses['default'];
	}, [onMobile, onSubPanel]);
	return (
		<div
			id="thumbnail-list"
			className={classNames('saola-scrollbar invisible-scrollbar mx-auto bg-primary-dark', styles)}
		>
			{thumbnails.map(
				({
					displaySetInstanceUID,
					description,
					dragData,
					seriesNumber,
					numInstances,
					modality,
					componentType,
					seriesDate,
					orientation,
					viewportIdentificator,
					isTracked,
					canReject,
					onReject,
					imageSrc,
					messages,
					imageAltText,
					isHydratedForDerivedDisplaySet,
					loadingProgress
				}) => {
					const isActive = activeDisplaySetInstanceUIDs.includes(displaySetInstanceUID);
					switch (componentType) {
						case 'thumbnail':
							return (
								<Thumbnail
									key={displaySetInstanceUID}
									displaySetInstanceUID={displaySetInstanceUID}
									dragData={dragData}
									description={description}
									seriesNumber={seriesNumber}
									numInstances={numInstances}
									orientation={orientation}
									imageSrc={imageSrc}
									imageAltText={imageAltText}
									messages={messages}
									viewportIdentificator={viewportIdentificator}
									isActive={isActive}
									onClick={() => onThumbnailClick(displaySetInstanceUID)}
									onDoubleClick={() => onThumbnailDoubleClick(displaySetInstanceUID)}
									loadingProgress={loadingProgress}
								/>
							);
						case 'thumbnailTracked':
							return (
								<ThumbnailTracked
									key={displaySetInstanceUID}
									displaySetInstanceUID={displaySetInstanceUID}
									dragData={dragData}
									description={description}
									seriesNumber={seriesNumber}
									numInstances={numInstances}
									orientation={orientation}
									imageSrc={imageSrc}
									imageAltText={imageAltText}
									messages={messages}
									viewportIdentificator={viewportIdentificator}
									isTracked={isTracked}
									isActive={isActive}
									onClick={() => onThumbnailClick(displaySetInstanceUID)}
									onDoubleClick={() => onThumbnailDoubleClick(displaySetInstanceUID)}
									onClickUntrack={() => onClickUntrack(displaySetInstanceUID)}
									loadingProgress={loadingProgress}
								/>
							);
						case 'thumbnailNoImage':
							return (
								<ThumbnailNoImage
									isActive={isActive}
									key={displaySetInstanceUID}
									displaySetInstanceUID={displaySetInstanceUID}
									dragData={dragData}
									modality={modality}
									modalityTooltip={_getModalityTooltip(modality)}
									messages={messages}
									seriesDate={seriesDate}
									description={description}
									canReject={canReject}
									onReject={onReject}
									onClick={() => onThumbnailClick(displaySetInstanceUID)}
									onDoubleClick={() => onThumbnailDoubleClick(displaySetInstanceUID)}
									viewportIdentificator={viewportIdentificator}
									isHydratedForDerivedDisplaySet={isHydratedForDerivedDisplaySet}
									loadingProgress={loadingProgress}
								/>
							);
						default:
							return <></>;
					}
				}
			)}
		</div>
	);
};

ThumbnailList.propTypes = {
	thumbnails: PropTypes.arrayOf(
		PropTypes.shape({
			displaySetInstanceUID: PropTypes.string.isRequired,
			imageSrc: PropTypes.string,
			imageAltText: PropTypes.string,
			seriesDate: PropTypes.string,
			seriesNumber: Types.StringNumber,
			numInstances: PropTypes.number,
			description: PropTypes.string,
			componentType: Types.ThumbnailType.isRequired,
			viewportIdentificator: Types.StringArray,
			isTracked: PropTypes.bool,
			/**
			 * Data the thumbnail should expose to a receiving drop target. Use a matching
			 * `dragData.type` to identify which targets can receive this draggable item.
			 * If this is not set, drag-n-drop will be disabled for this thumbnail.
			 *
			 * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
			 */
			dragData: PropTypes.shape({
				/** Must match the "type" a dropTarget expects */
				type: PropTypes.string.isRequired
			})
		})
	),
	activeDisplaySetInstanceUIDs: PropTypes.arrayOf(PropTypes.string),
	onThumbnailClick: PropTypes.func.isRequired,
	onThumbnailDoubleClick: PropTypes.func.isRequired,
	onClickUntrack: PropTypes.func.isRequired,
	servicesManager: PropTypes.object,
	isTracked: PropTypes.bool,
	onSubPanel: PropTypes.bool,
	onMobile: PropTypes.bool
};

// TODO: Support "Viewport Identificator"?
function _getModalityTooltip(modality) {
	if (Object.prototype.hasOwnProperty.call(_modalityTooltips, modality)) {
		return _modalityTooltips[modality];
	}

	return 'Unknown';
}

const _modalityTooltips = {
	SR: 'Structured Report',
	SEG: 'Segmentation',
	RTSTRUCT: 'RT Structure Set'
};

export default ThumbnailList;
