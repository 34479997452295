import localforage from 'localforage';

localforage.config({
	driver: localforage.INDEXEDDB, // Chọn IndexedDB làm driver
	name: 'viewer',
	version: 1.0,
	storeName: 'viewerConfig'
});

export default localforage;
