import getAttribute from './getAttribute';
import getAuthorizationHeader from './getAuthorizationHeader';
import getModalities from './getModalities';
import getName from './getName';
import getNumber from './getNumber';
import getString from './getString';

const DICOMWeb = {
	getAttribute,
	getAuthorizationHeader,
	getModalities,
	getName,
	getNumber,
	getString
};

export { getAttribute, getAuthorizationHeader, getModalities, getName, getNumber, getString };

export default DICOMWeb;
