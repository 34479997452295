import React from 'react';

const AboutModal = () => {
	const version = document.querySelector('meta[name="version"]') as HTMLMetaElement;

	return (
		<div className="grid items-center gap-2 text-xs font-light">
			<div className="grid grid-cols-5  items-center">
				<div className="col-span-2 text-common-light">Softwave name</div>
				<div className="col-span-3">Saola DICOM Viewer</div>
			</div>
			<div className="grid grid-cols-5  items-center">
				<div className="col-span-2 text-common-light">Version number</div>
				<div className="col-span-3">{version?.content}</div>
			</div>
			<div className="grid grid-cols-5  items-center">
				<div className="col-span-2 text-common-light">License number</div>
				<div className="col-span-3">123456789.987654321</div>
			</div>
			<div className="grid grid-cols-5  items-center">
				<div className="col-span-2 text-common-light">Support</div>
				<div className="col-span-3">SupportPro@saolasoft.vn</div>
			</div>
		</div>
	);
};

export default AboutModal;
