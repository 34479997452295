import { Enums, ServicesManager } from '@core';
import { GridViewports, Layout } from '@core/types/ViewportGridType';
import { LoadingIndicatorProgress } from '@ui';
import React, { Suspense, useCallback } from 'react';

// Hiển thị loading trên viewport grid
const ViewportGridLoadingProgress = ({
	layout,
	viewports,
	extensionManager,
	servicesManager
}: {
	layout: Layout;
	viewports: GridViewports;
	extensionManager;
	servicesManager: ServicesManager;
}) => {
	const { numCols, numRows } = layout;

	const renderLoadingProgress = useCallback(() => {
		const { component: RemoteRenderLoading } = extensionManager.getModuleEntry(
			'@extensions/remote-render.viewportModule.remoteRenderViewportLoading'
		);

		if (viewports) {
			const viewport = viewports.values().next().value;
			const viewportType = viewport.viewportOptions?.viewportType;
			// có component loading riêng cho remote viewport
			if (viewportType === Enums.ViewportTypes.REMOTE_RENDER) {
				return (
					<Suspense>
						<RemoteRenderLoading servicesManager={servicesManager} />
					</Suspense>
				);
			}
			if (!numRows || !numCols) {
				return <LoadingIndicatorProgress className={'h-full w-full'} />;
			}
			return <></>;
		}

		return <LoadingIndicatorProgress className={'h-full w-full'} />;
	}, [extensionManager, numCols, numRows, servicesManager, viewports]);
	return <>{renderLoadingProgress()}</>;
};

export default ViewportGridLoadingProgress;
