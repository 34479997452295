export const createWwwcPreset = ({
	type,
	preset,
	isVisibleShortcutKey = true
}: {
	type: 'NORMAL' | 'PSEUDO' | 'USER_DEFINE';
	preset: {
		description: string;
		window: string;
		level: string;
	};
	isVisibleShortcutKey?: boolean;
}) => {
	const subtitle = preset.window && preset.level ? `${preset.window}/${preset.level}` : '';
	return {
		id: preset.description,
		title: preset.description,
		subtitle,
		type: 'action',
		isVisibleShortcutKey,
		commands: [
			{
				commandName: 'setWindowLevel',
				commandOptions: {
					window: preset.window,
					level: preset.level,
					type
				},
				context: 'CORNERSTONE'
			}
		]
	};
};
