import React from 'react';
import ReactDOM from 'react-dom/client';
import 'regenerator-runtime/runtime';

import App from './App';
import './index.css';
import loadDynamicConfig from './loadDynamicConfig';
import { extensions as defaultExtensions, modes as defaultModes } from './pluginImports';
import reportWebVitals from './reportWebVitals';

loadDynamicConfig(window.config).then((config_json: object) => {
	// Reset Dynamic config if defined
	if (config_json !== null) {
		window.config = config_json;
	}

	/**
	 * Combine our appConfiguration with installed extensions and modes.
	 * In the future appConfiguration may contain modes added at runtime.
	 *  */
	const appProps = {
		config: window ? window.config : {},
		defaultExtensions,
		defaultModes
	};

	const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
	root.render(
		// <React.StrictMode>
		<App {...appProps} />
		// </React.StrictMode>
	);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
