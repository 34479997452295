import { getNewStudies } from '@utils';
import isSeriesFilterUsed from '../../utils/isSeriesFilterUsed';

import { DicomMetadataStore } from '@core';

/**
 * Initialize the route.
 *
 * @param props.servicesManager to read services from
 * @param props.studyInstanceUIDs for a list of studies to read
 * @param props.dataSource to read the data from
 * @param props.filters filters from query params to read the data from
 * @returns array of subscriptions to cancel
 */
export async function defaultRouteInit(
	{ servicesManager, studyInstanceUIDs, dataSource, filters, appConfig, commandsManager },
	hangingProtocolId
) {
	const { displaySetService, uiNotificationService } = servicesManager.services;
	const unsubscriptions = [];
	const issuedWarningSeries = [];
	const { unsubscribe: instanceAddedUnsubscribe } = DicomMetadataStore.subscribe(
		DicomMetadataStore.EVENTS.INSTANCES_ADDED,
		function ({ StudyInstanceUID, SeriesInstanceUID, madeInClient = false }) {
			const seriesMetadata = DicomMetadataStore.getSeries(StudyInstanceUID, SeriesInstanceUID);
			if (!seriesMetadata) {
				return;
			}
			// checks if the series filter was used, if it exists
			const seriesInstanceUIDs = filters?.seriesInstanceUID;
			if (
				seriesInstanceUIDs?.length &&
				!isSeriesFilterUsed(seriesMetadata.instances, filters) &&
				!issuedWarningSeries.includes(seriesInstanceUIDs[0])
			) {
				// stores the series instance filter so it shows only once the warning
				issuedWarningSeries.push(seriesInstanceUIDs[0]);
				uiNotificationService.show({
					title: 'Series filter',
					message: `Each of the series in filter: ${seriesInstanceUIDs} are not part of the current study. The entire study is being displayed`,
					type: 'error',
					duration: 7000
				});
			}
			displaySetService.makeDisplaySets(seriesMetadata.instances, madeInClient);
		}
	);

	unsubscriptions.push(instanceAddedUnsubscribe);

	await getNewStudies({ commandsManager, dataSource, hangingProtocolId, servicesManager, studyInstanceUIDs });

	return unsubscriptions;
}
