import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

import { SessionInfomationActionType, SessionWithKey } from 'types';

type SessionInfomationAction = {
	type: SessionInfomationActionType;
	payload: SessionWithKey;
};

const DEFAULT_STATE: SessionWithKey = {
	anonymized: false,
	expiredTime: null,
	id: null,
	requiresShareKey: false,
	shareKey: null,
	shared: false,
	storeAuth: null,
	storeUrl: null,
	studies: undefined,
	user: null,
	sessionKey: null
};

type SessionInfomationContextValues = {
	session: SessionWithKey;
	func: {
		setSession: (user: SessionWithKey) => void;
		getState: () => SessionWithKey;
		resetSession: () => void;
		getSessionKeyHeader: () => { 'session-key': string };
	};
};

export const SessionInfomationContext = createContext<SessionInfomationContextValues>({
	session: DEFAULT_STATE,
	func: {
		setSession: null,
		getState: null,
		resetSession: null,
		getSessionKeyHeader: null
	}
});

/**
 * Session Authentication Reducer
 */
const sessionAuthenticationReducer = (state: SessionWithKey, action: SessionInfomationAction) => {
	switch (action.type) {
		case 'UPDATE_SESSION': {
			return {
				...state,
				...action.payload
			};
		}
		case 'RESET': {
			return {};
		}
		default:
			return action.payload;
	}
};

export function SessionAuthenticationProvider({ children, service }) {
	const [sessionAuthenticationState, dispatch] = useReducer(sessionAuthenticationReducer, DEFAULT_STATE);
	const getState = useCallback(() => sessionAuthenticationState, [sessionAuthenticationState]);

	const getSessionKeyHeader = useCallback(() => {
		if (sessionAuthenticationState.sessionKey) {
			return {
				'session-key': sessionAuthenticationState.sessionKey
			};
		}
		return undefined;
	}, [sessionAuthenticationState]);

	const setSession = useCallback(
		(session: SessionWithKey) =>
			dispatch({
				type: 'UPDATE_SESSION',
				payload: session
			}),
		[dispatch]
	);

	const resetSession = useCallback(
		() =>
			dispatch({
				type: 'RESET',
				payload: {}
			}),
		[dispatch]
	);

	/**
	 * Sets the implementation of the SessionAuthenticationService that can be used by extensions.
	 * @returns void
	 */
	// TODO: should this be a useEffect or not?
	useEffect(() => {
		if (service) {
			service.setServiceImplementation({
				getState,
				resetSession,
				setSession,
				getSessionKeyHeader
			});
		}
	}, [getSessionKeyHeader, getState, resetSession, service, setSession]);

	// if (service) {
	// 	service.setServiceImplementation({
	// 		getState,
	// 		resetSession,
	// 		setSession,
	// 		getSessionKeyHeader
	// 	});
	// }

	const sessionProviderValue = useMemo(
		() => ({
			session: sessionAuthenticationState,
			func: {
				setSession,
				resetSession,
				getState,
				getSessionKeyHeader
			}
		}),
		[getSessionKeyHeader, getState, resetSession, setSession, sessionAuthenticationState]
	);

	return <SessionInfomationContext.Provider value={sessionProviderValue}>{children}</SessionInfomationContext.Provider>;
}

export default SessionAuthenticationProvider;

const SessionInfomationConsumer = SessionInfomationContext.Consumer;

export { SessionInfomationConsumer };

SessionAuthenticationProvider.propTypes = {
	children: PropTypes.any,
	service: PropTypes.shape({
		setServiceImplementation: PropTypes.func
	}).isRequired
};

export const useUserAuthentication = () => useContext(SessionInfomationContext);
