import React from 'react';

import { useModal } from '../../contextProviders';
import ToolbarButton from '../ToolbarButton';
import AboutModal from './AboutModal';

const AboutButton = () => {
	const { show, hide } = useModal();

	return (
		<ToolbarButton
			icon={'info'}
			label={'About'}
			onInteraction={() =>
				show({
					content: AboutModal,
					title: 'About'
				})
			}
			id=""
			dropdownContent=""
			type="action"
		/>
	);
};
export default AboutButton;
