/**
 * adds a pause and unpause method to Mousetrap
 * this allows you to enable or disable keyboard shortcuts
 * without having to reset Mousetrap and rebind everything
 *
 * https://github.com/ccampbell/mousetrap/blob/master/plugins/pause/mousetrap-pause.js
 */
export default function pausePlugin(Mousetrap) {
	var _originalStopCallback = Mousetrap.prototype.stopCallback;

	Mousetrap.prototype.stopCallback = function (e, element, combo) {
		if (this.paused) {
			return true;
		}

		return _originalStopCallback.call(this, e, element, combo);
	};

	Mousetrap.prototype.pause = function () {
		this.paused = true;
	};

	Mousetrap.prototype.unpause = function () {
		this.paused = false;
	};

	Mousetrap.init();
}
