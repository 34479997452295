import React, { useCallback } from 'react';
import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	Row,
	RowSelectionState,
	useReactTable
} from '@tanstack/react-table';

export const getRowId = <T,>(original: T, index: number) => {
	const org = original as Record<string, unknown>;
	if (org && org.id) {
		return `${org.id}`;
	}
	return `${index}`;
};

type TableProps<T> = {
	data: T[];
	columns: ColumnDef<T>[];
	filter?: string;
	onRowClick?: (row: T) => void;
	enableRowSelection?: boolean | ((row: Row<T>) => boolean);
	setRowSelection?: React.Dispatch<React.SetStateAction<RowSelectionState>>;
	rowSelection?: RowSelectionState;
};

const BasicTable = <T,>(props: TableProps<T>) => {
	const { data, columns, filter, onRowClick, enableRowSelection = false, rowSelection = {}, setRowSelection } = props;
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableRowSelection,
		state: {
			globalFilter: filter,
			rowSelection
		},
		onRowSelectionChange: setRowSelection,
		columnResizeMode: 'onChange',
		defaultColumn: {
			minSize: 10
		},
		getRowId
	});

	const onClickTableRow = useCallback(
		(row: Row<T>) => {
			onRowClick && onRowClick(row.original);
		},
		[onRowClick]
	);

	return (
		<div className="table-container invisible-scrollbar h-full w-full overflow-auto border border-common-light">
			<table
				className="relative min-w-full border-collapse"
				{...{
					style: {
						width: table.getTotalSize()
					}
				}}
			>
				<thead className="sticky top-0 h-[28px] border-b border-common-light bg-primary-light">
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{/* Thêm checkbox để chọn tất cả các hàng */}
							{table.options.enableRowSelection && (
								<th className="items-center text-sm text-common-light">
									<div>
										{/* <input
											type="checkbox"
											checked={table.getIsAllPageRowsSelected()}
											onChange={table.getToggleAllRowsSelectedHandler()}
										/> */}
									</div>
								</th>
							)}
							{headerGroup.headers.map((header) => (
								<th
									className="items-center text-sm text-common-light"
									key={header.id}
									{...{
										style: {
											width: `${header.getSize()}%`
										}
									}}
								>
									<div className="flex w-full justify-center">
										{flexRender(header.column.columnDef.header, header.getContext())}
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map((row) => (
						<tr
							key={row.id}
							onClick={() => onClickTableRow(row)}
							className={`h-[28px] break-words border-b border-common-light text-[13px] font-light text-common-light ${
								row.getIsSelected() ? 'bg-primary-light' : ''
							}`}
						>
							{/* Checkbox cho mỗi hàng */}
							{table.options.enableRowSelection && (
								<th className="items-center text-sm text-common-light">
									{row.getCanSelect() && (
										<input
											type="checkbox"
											checked={row.getIsSelected()}
											onChange={row.getToggleSelectedHandler()}
											onClick={(e) => e.stopPropagation()} // Để ngăn việc click vào checkbox kích hoạt onRowClick
										/>
									)}
								</th>
							)}
							{row.getVisibleCells().map((cell) => (
								<td
									key={cell.id}
									{...{
										style: {
											width: `${cell.column.getSize()}%`,
											maxWidth: `${cell.column.getSize()}%`
										}
									}}
								>
									<div className="flex w-full justify-center">
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</div>
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default BasicTable;
