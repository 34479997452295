import windowLevelPresets from './windowLevelPresets';

/*
 * Supported Keys: https://craig.is/killing/mice
 */
const bindings = [
	{
		commandName: 'setToolActive',
		commandOptions: {
			toolName: 'Zoom'
		},
		label: 'Zoom',
		keys: ['z'],
		isEditable: true
	},
	{
		commandName: 'scaleUpViewport',
		label: 'Zoom In',
		keys: ['+'],
		isEditable: true
	},
	{
		commandName: 'scaleDownViewport',
		label: 'Zoom Out',
		keys: ['-'],
		isEditable: true
	},
	{
		commandName: 'fitViewportToWindow',
		label: 'Zoom to Fit',
		keys: ['='],
		isEditable: true
	},
	// {
	// 	commandName: 'toggleCine',
	// 	label: 'Cine',
	// 	keys: ['c']
	// },
	{
		commandName: 'invertViewport',
		label: 'Invert',
		keys: ['ctrl+r'],
		isEditable: true
	},
	{
		commandName: 'incrementActiveViewport',
		label: 'Next Image Viewport',
		keys: ['right'],
		isEditable: true
	},
	{
		commandName: 'decrementActiveViewport',
		label: 'Previous Image Viewport',
		keys: ['left'],
		isEditable: true
	},
	{
		commandName: 'updateViewportDisplaySet',
		commandOptions: {
			direction: -1
		},
		label: 'Previous Series',
		keys: ['pageup'],
		isEditable: true
	},
	{
		commandName: 'updateViewportDisplaySet',
		commandOptions: {
			direction: 1
		},
		label: 'Next Series',
		keys: ['pagedown'],
		isEditable: true
	},
	{
		commandName: 'nextStage',
		context: 'DEFAULT',
		label: 'Next Stage',
		keys: ['.'],
		isEditable: true
	},
	{
		commandName: 'previousStage',
		context: 'DEFAULT',
		label: 'Previous Stage',
		keys: [','],
		isEditable: true
	},
	{
		commandName: 'nextImage',
		label: 'Next Image',
		keys: ['down'],
		isEditable: true
	},
	{
		commandName: 'previousImage',
		label: 'Previous Image',
		keys: ['up'],
		isEditable: true
	},
	{
		commandName: 'firstImage',
		label: 'First Image',
		keys: ['home'],
		isEditable: true
	},
	{
		commandName: 'lastImage',
		label: 'Last Image',
		keys: ['end'],
		isEditable: true
	},
	{
		commandName: 'resetViewport',
		label: 'Reset',
		keys: ['space'],
		isEditable: true
	},
	{
		commandName: 'cancelMeasurement',
		label: 'Cancel Cornerstone Measurement',
		keys: ['esc']
	},
	{
		commandName: 'setWindowLevel',
		commandOptions: windowLevelPresets[1],
		label: 'W/L Preset 1',
		keys: ['1']
	},
	{
		commandName: 'setWindowLevel',
		commandOptions: windowLevelPresets[2],
		label: 'W/L Preset 2',
		keys: ['2']
	},
	{
		commandName: 'setWindowLevel',
		commandOptions: windowLevelPresets[3],
		label: 'W/L Preset 3',
		keys: ['3']
	},
	{
		commandName: 'setWindowLevel',
		commandOptions: windowLevelPresets[4],
		label: 'W/L Preset 4',
		keys: ['4']
	},
	{
		commandName: 'setWindowLevel',
		commandOptions: windowLevelPresets[5],
		label: 'W/L Preset 5',
		keys: ['5']
	},
	// These don't exist, so don't try applying them....
	// {
	//   commandName: 'setWindowLevel',
	//   commandOptions: windowLevelPresets[6],
	//   label: 'W/L Preset 6',
	//   keys: ['6'],
	// },
	// {
	//   commandName: 'setWindowLevel',
	//   commandOptions: windowLevelPresets[7],
	//   label: 'W/L Preset 7',
	//   keys: ['7'],
	// },
	// {
	//   commandName: 'setWindowLevel',
	//   commandOptions: windowLevelPresets[8],
	//   label: 'W/L Preset 8',
	//   keys: ['8'],
	// },
	// {
	//   commandName: 'setWindowLevel',
	//   commandOptions: windowLevelPresets[9],
	//   label: 'W/L Preset 9',
	//   keys: ['9'],
	// },
	{
		commandName: 'setToolActive',
		commandOptions: {
			toolName: 'HoverMagnify'
		},
		label: 'Magnify',
		keys: ['m'],
		isEditable: true
	},
	{
		commandName: 'setToolActive',
		commandOptions: {
			toolName: 'Pan'
		},
		label: 'Pan',
		keys: ['p'],
		isEditable: true
	},
	{
		commandName: 'setToolActive',
		commandOptions: {
			toolName: 'PlanarRotate'
		},
		label: 'Rotate',
		keys: ['shift+r'],
		isEditable: true
	},
	{
		commandName: 'setToolActive',
		commandOptions: {
			toolName: 'WindowLevel'
		},
		label: 'Window Level',
		keys: ['w'],
		isEditable: true
	},
	{
		commandName: 'deleteMeasurements',
		label: 'Delete all',
		keys: ['ctrl+del'],
		isEditable: true
	},
	{
		commandName: 'setToolActive',
		label: 'Delete Selected',
		commandOptions: {
			toolName: 'AnnotationEraser'
		},
		keys: ['del'],
		isEditable: true
	},
	{
		commandName: 'rotateViewportCW',
		label: 'Rotate Right',
		keys: ['shift+right'],
		isEditable: true
	},
	{
		commandName: 'rotateViewportCCW',
		label: 'Rotate Left',
		keys: ['shift+left'],
		isEditable: true
	},
	{
		commandName: 'flipViewportVertical',
		label: 'Flip Vertical',
		keys: ['ctrl+up', 'ctrl+down'],
		isEditable: true
	},
	{
		commandName: 'flipViewportHorizontal',
		label: 'Flip Horizontal',
		keys: ['ctrl+left', 'ctrl+right'],
		isEditable: true
	},
	{
		commandName: 'setToolActive',
		label: 'Length',
		commandOptions: {
			toolName: 'Length'
		},
		context: 'CORNERSTONE',
		isEditable: false,
		keys: ['l']
	},
	{
		commandName: 'setToolActive',
		label: 'Ellipse',
		commandOptions: {
			toolName: 'EllipticalROI'
		},
		context: 'CORNERSTONE',
		isEditable: false,
		keys: ['e']
	},
	{
		commandName: 'setToolActive',
		label: 'Rectangle',
		commandOptions: {
			toolName: 'RectangleROI'
		},
		context: 'CORNERSTONE',
		isEditable: false,
		keys: ['r']
	},
	{
		commandName: 'setToolActive',
		label: 'Cross',
		commandOptions: {
			toolName: 'Bidirectional'
		},
		context: 'CORNERSTONE',
		isEditable: false,
		keys: ['y']
	},
	{
		commandName: 'setToolActive',
		label: 'StackScroll',
		commandOptions: {
			toolName: 'StackScroll'
		},
		context: 'CORNERSTONE',
		isEditable: false,
		keys: ['s']
	},
	{
		commandName: 'setToolActive',
		label: 'CircleROI',
		commandOptions: {
			toolName: 'CircleROI'
		},
		context: 'CORNERSTONE',
		isEditable: false,
		keys: ['o']
	},
	{
		commandName: 'setToolActive',
		label: 'Angle',
		commandOptions: {
			toolName: 'Angle'
		},
		context: 'CORNERSTONE',
		isEditable: false,
		keys: ['a']
	},
	{
		commandName: 'setToolActive',
		label: 'Cobb Angle',
		commandOptions: {
			toolName: 'CobbAngle'
		},
		context: 'CORNERSTONE',
		isEditable: false,
		keys: ['c']
	},
	{
		commandName: 'toggleSwitchMPR',
		label: 'Switch MPR - Axial',
		commandOptions: {
			orientation: 'axial'
		},
		context: 'CORNERSTONE',
		isEditable: false,
		keys: ['shift+a']
	},
	{
		commandName: 'toggleSwitchMPR',
		label: 'Switch MPR - Coronal',
		commandOptions: {
			orientation: 'coronal'
		},
		context: 'CORNERSTONE',
		isEditable: false,
		keys: ['shift+c']
	},
	{
		commandName: 'toggleSwitchMPR',
		label: 'Switch MPR - Sagittal',
		commandOptions: {
			orientation: 'sagittal'
		},
		context: 'CORNERSTONE',
		isEditable: false,
		keys: ['shift+s']
	},
	{
		commandName: 'setToolActive',
		commandOptions: {
			toolName: 'TrackballRotate',
			toolGroupId: 'mpr'
		},
		context: 'CORNERSTONE',
		keys: ['ctrl+shift+r']
	},
	{
		commandName: 'setToolActive',
		commandOptions: {
			toolName: 'Probe'
		},
		context: 'CORNERSTONE',
		keys: ['shift+p']
	},
	{
		commandName: 'setToolActive',
		commandOptions: {
			toolName: 'SplineROI'
		},
		context: 'CORNERSTONE',
		keys: ['h']
	},
	{
		commandName: 'setToolActive',
		label: 'Heart / Chest',
		commandOptions: {
			toolName: 'HeartChest'
		},
		context: 'CORNERSTONE',
		isEditable: false,
		keys: ['t']
	},
	{
		commandName: 'setToolActive',
		commandOptions: {
			toolName: 'Crosshairs'
		},
		context: 'CORNERSTONE',
		isEditable: false,
		keys: ['q']
	},
	{
		commandName: 'setToolActive',
		commandOptions: {
			toolName: 'ReferenceCursors'
		},
		context: 'CORNERSTONE',
		isEditable: false,
		keys: ['q']
	}
];

export default bindings;
