import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { useOnClickOutside } from '@hooks';

const noop = (..._args) => undefined;

const LayoutSelector = ({ onSelection = noop, rows = 5, columns = 5, onClickOutside = noop }) => {
	const ref = useRef(null);

	useOnClickOutside(ref, onClickOutside);

	const [hoveredIndex, setHoveredIndex] = useState(-1);
	const hoverX = hoveredIndex % columns;
	const hoverY = Math.floor(hoveredIndex / columns);
	const isHovered = (index) => {
		const x = index % columns;
		const y = Math.floor(index / columns);

		return x <= hoverX && y <= hoverY;
	};

	const gridSize = '20px ';
	const totalGrid = rows * columns;

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: gridSize.repeat(columns),
				gridTemplateRows: gridSize.repeat(rows)
			}}
			className="rounded border border-common-dark bg-primary-dark p-2"
			id="layout-chooser"
			ref={ref}
		>
			{Array(totalGrid)
				.fill(null, 0, totalGrid)
				.map((_, index) => (
					<div
						key={index}
						style={{
							border: '1px solid #1c1c1c',
							backgroundColor: isHovered(index) ? '#cccccc' : '#444444'
						}}
						data-cy={`Layout-${index % columns}-${Math.floor(index / columns)}`}
						className="cursor-pointer"
						onClick={() => {
							const x = index % columns;
							const y = Math.floor(index / columns);

							onSelection({
								numRows: y + 1,
								numCols: x + 1
							});
						}}
						onMouseEnter={() => setHoveredIndex(index)}
						onMouseLeave={() => setHoveredIndex(-1)}
					></div>
				))}
		</div>
	);
};

LayoutSelector.propTypes = {
	onSelection: PropTypes.func.isRequired,
	columns: PropTypes.number.isRequired,
	rows: PropTypes.number.isRequired,
	onClickOutside: PropTypes.func
};

export default LayoutSelector;
