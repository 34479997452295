import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const appConfigContext = createContext(null);
const { Provider } = appConfigContext;

export const useAppConfig = () => useContext(appConfigContext);

export function AppConfigProvider({ children, value: initAppConfig }) {
	const [appConfig, _setAppConfig] = useState(initAppConfig);

	return <Provider value={appConfig}>{children}</Provider>;
}

AppConfigProvider.propTypes = {
	children: PropTypes.any,
	value: PropTypes.any
};

export default AppConfigProvider;
