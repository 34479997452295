// Return the array sorting function for its object's properties
const sortBy = (...args) => {
	const fields = [].slice.call(args),
		n_fields = fields.length;

	return function (A: { [x: string]: any }, B: { [x: string]: any }) {
		let a: number,
			b: number,
			field: { name: any; primer: (arg0: any) => any; reverse: any },
			key: string | number,
			reverse: number,
			result: number,
			i: number;

		for (i = 0; i < n_fields; i++) {
			result = 0;
			field = fields[i];

			key = typeof field === 'string' ? field : field.name;

			a = A[key];
			b = B[key];

			if (typeof field.primer !== 'undefined') {
				a = field.primer(a);
				b = field.primer(b);
			}

			reverse = field.reverse ? -1 : 1;

			if (a < b) {
				result = reverse * -1;
			}

			if (a > b) {
				result = reverse * 1;
			}

			if (result !== 0) {
				break;
			}
		}

		return result;
	};
};

export default sortBy;
