import { SessionDTO } from 'types';
import { PubSubService } from '../_shared/pubSubServiceInterface';

const name = 'sessionAuthenticationService';

type SessionServiceImplementation = {
	_getState?: () => SessionDTO;
	_setSession?: (..._params) => unknown;
	_reset?: (..._params) => unknown;
	_getSessionKeyHeader?: (..._params) => unknown;
};

class SessionAuthenticationService extends PubSubService {
	public static readonly EVENTS = {
		STATE_CHANGED: 'event::stateChanged'
	};

	public static REGISTRATION = {
		name,
		altName: 'SessionAuthenticationService',
		create: () => {
			return new SessionAuthenticationService();
		}
	};

	serviceImplementation: SessionServiceImplementation = {};
	constructor() {
		super(SessionAuthenticationService.EVENTS);
		this.serviceImplementation = {};
	}

	public setServiceImplementation({
		getState: getStateImplementation,
		setSession: setImplementation,
		resetSession: resetImplementation,
		getSessionKeyHeader
	}) {
		if (getStateImplementation) {
			this.serviceImplementation._getState = getStateImplementation;
		}
		if (setImplementation) {
			this.serviceImplementation._setSession = setImplementation;
		}
		if (resetImplementation) {
			this.serviceImplementation._reset = resetImplementation;
		}
		if (getSessionKeyHeader) {
			this.serviceImplementation._getSessionKeyHeader = getSessionKeyHeader;
		}
	}

	public getState() {
		return this.serviceImplementation._getState && this.serviceImplementation._getState();
	}
	public setSession(state) {
		return this.serviceImplementation._setSession && this.serviceImplementation._setSession(state);
	}
	public reset() {
		return this.serviceImplementation._reset && this.serviceImplementation._reset({});
	}
	public getSessionKeyHeader(state?) {
		return this.serviceImplementation._reset && this.serviceImplementation._getSessionKeyHeader(state);
	}
}

export default SessionAuthenticationService;
