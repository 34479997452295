import moment from 'moment';

/**
 * Format date
 *
 * @param {string} date Date to be formatted
 * @param {string} format Desired date format
 * @returns {string} Formatted date
 */
const formatDate = (date, format = 'YYYY-MM-DD') => {
	// moment(undefined) returns the current date, so return the empty string instead
	return date ? moment(date).format(format) : '';
};

/**
 * Format date  time
 *
 * @param {string} date Date to be formatted
 * @param {string} format Desired date format
 * @returns {string} Formatted date
 */
const formatDateTime = (dateTime, format = 'DD/MM/YYYY HH:mm:ss') => {
	// moment(undefined) returns the current date, so return the empty string instead
	return dateTime ? moment(dateTime).format(format) : '';
};

export { formatDate, formatDateTime };
