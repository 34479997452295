import React, { FC, PropsWithChildren } from 'react';

import { useDisclosure } from '@hooks';
import classNames from 'classnames';
import Icon from '../Icon';

type CollapsibleBoxLayoutType = {
	title: string;
	collapsible?: boolean;
	className?: string;
	visible?: boolean;
	iconName?: string;
	titleClassName?: string;
};

const CollapsibleBoxLayout: FC<PropsWithChildren<CollapsibleBoxLayoutType>> = ({
	title,
	className,
	children,
	collapsible,
	visible,
	iconName = 'chevron-down',
	titleClassName
}) => {
	const { isOpen, toggle } = useDisclosure(collapsible);

	return visible ? (
		<div className={classNames('flex flex-1 flex-col')}>
			<div
				className="sticky cursor-pointer items-center bg-primary-main  text-common-light"
				onClick={toggle}
			>
				<div className={classNames('flex h-6 items-center justify-center border border-common-dark px-2 text-xs', titleClassName)}>
					{title}
				</div>
				<div
					className={classNames(
						'absolute right-0 top-0 flex h-[24px] cursor-pointer items-center justify-center',
						'h-6 w-6 hover:bg-primary-light',
						'rounded-sm'
					)}
				>
					<Icon
						name={iconName}
						className={classNames('text-common-bright', !isOpen && 'rotate-180')}
					/>
				</div>
			</div>
			{<div className={classNames('', !isOpen && 'hidden')}>{children}</div>}
		</div>
	) : (
		<></>
	);
};

export default CollapsibleBoxLayout;
