import { useCallback } from 'react';

import { CommandsManager, utils } from '@core';
import { RESOURCES, SessionDTO } from 'types';
import { useAppConfig } from './../state/appConfig';
import useSearchParams from './useSearchParams';

export const useSession = (commandsManager: CommandsManager) => {
	const appConfig = useAppConfig();
	const baseURL = appConfig.dataSources[0].configuration.apiRoot;
	const searchParams = useSearchParams();
	const sessionID = utils.splitComma(searchParams.getAll(RESOURCES.SESSION))[0];
	/**
	 * Lấy thông tin session by ID
	 */
	const getSession = useCallback(async (): Promise<SessionDTO> => {
		let session: SessionDTO;
		if (sessionID) {
			try {
				const response = await fetch(`${baseURL}/${RESOURCES.SESSION}/${sessionID}`);
				session = await response.json();
			} catch (error) {
				console.log(error);
				commandsManager.run({ commandName: 'showCannotViewImagesPopup' });

				// Handle any error happened.
			}
		}

		return session;
	}, [baseURL, commandsManager, sessionID]);

	return { getSession };
};
