const MODULE_TYPES = {
	COMMANDS: 'commandsModule',
	CUSTOMIZATION: 'customizationModule',
	STATE_SYNC: 'stateSyncModule',
	DATA_SOURCE: 'dataSourcesModule',
	PANEL: 'panelModule',
	SOP_CLASS_HANDLER: 'sopClassHandlerModule',
	TOOLBAR: 'toolbarModule',
	VIEWPORT: 'viewportModule',
	CONTEXT: 'contextModule',
	LAYOUT_TEMPLATE: 'layoutTemplateModule',
	HANGING_PROTOCOL: 'hangingProtocolModule',
	UTILITY: 'utilityModule'
};

export default MODULE_TYPES;
