import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppConfig } from '@state';
import { LinkDTO } from 'types/Link';

export const useClientLink = () => {
	const appConfig = useAppConfig();
	const baseURL = appConfig.dataSources[0].configuration.apiRoot;
	const wadoURL = appConfig.dataSources[0].configuration.wadoRoot;
	const navigate = useNavigate();

	/**
	 * Gọi sang BE baseURL tạo Link viewer
	 * từ studyInstanceUid
	 * với storeUrl là wadoURL trong file config
	 */
	const getLink = useCallback(
		async (studyInstanceUid: string): Promise<LinkDTO> => {
			let clientLinkData: LinkDTO;

			try {
				const response = await fetch(`${baseURL}/client/link`, {
					// learn more about this API here: https://graphql-pokemon2.vercel.app/
					method: 'POST',
					headers: {
						'content-type': 'application/json;charset=UTF-8'
					},
					body: JSON.stringify({
						expireIn: 9999,
						storeUrl: wadoURL,
						studies: [studyInstanceUid]
					})
				});
				if (response.ok) {
					clientLinkData = await response.json();
					// ...do something with the response
				} else {
					console.error('Promise resolved but HTTP status failed');
				}
			} catch (error) {
				console.log(error);
			}

			return clientLinkData;
		},
		[baseURL, wadoURL]
	);
	/**
	 * Mở Viewer với params studyInstanceUIDs
	 */
	const openViewerStandaloneMode = useCallback(
		(studyInstanceUid: string) => {
			navigate(`viewer?studyInstanceUIDs=${studyInstanceUid}`);
		},
		[navigate]
	);

	/**
	 * Mở link viewer từ studyInstanceUid
	 */
	const openViewer = useCallback(
		async (studyInstanceUid: string) => {
			if (appConfig.standalone) {
				openViewerStandaloneMode(studyInstanceUid);
			} else {
				const clientLinkData = await getLink(studyInstanceUid);
				const params = clientLinkData.link.replace('/index.html', '');
				navigate(params);
			}
		},
		[appConfig.standalone, getLink, navigate, openViewerStandaloneMode]
	);

	return { getLink, openViewer };
};
