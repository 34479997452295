import React from 'react';
import './Spinner.css';

const Spinner = ({ classes }: { classes: string }) => {
	return (
		<div className={classes}>
			<div className="spinner"></div>
		</div>
	);
};

export default Spinner;
