import { PubSubService } from '../_shared/pubSubServiceInterface';

/**
 * Serive xử lý luồng nếu 1 data được thay đổi ->  component nào đó cần theo dõi để hiển thị theo data mới
 */
class DataTrackingService extends PubSubService {
	public static readonly EVENTS = {
		PRESET_DATA_CHANGED: 'event::presetdatachange'
	};

	public static REGISTRATION = {
		name: 'dataTrackingService',
		altName: 'DataTrackingService',
		create: ({ configuration = {} }) => {
			return new DataTrackingService();
		}
	};

	constructor() {
		super(DataTrackingService.EVENTS);
	}
	public presetDataChanged(id: string) {
		this._broadcastEvent(`${this.EVENTS.PRESET_DATA_CHANGED}`, { id });
	}
	// public otherDataChanged() {}
}

export default DataTrackingService;
