import React from 'react';
import { Icon, IconButton } from '@ui';

const CustomButton = ({ onClick, iconName }: { onClick: () => void; iconName: string }) => {
	return (
		<IconButton
			id={iconName}
			variant="text"
			color="inherit"
			size="initial"
			className={'h-4 w-4 bg-transparent hover:!text-primary-active'}
			onClick={onClick}
		>
			<Icon name={iconName} />
		</IconButton>
	);
};

/**
 * Các nút sắp xếp index preset, xóa preset
 */
export const TableActionButton = ({
	numOfPreset,
	onChangeIndex,
	deletePreset,
	index
}: {
	numOfPreset: number;
	onChangeIndex: (index: number, upper: boolean) => Promise<void>;
	deletePreset: (index: number) => Promise<void>;
	index: number;
}) => {
	return (
		<div className="flex w-full flex-row items-center justify-end gap-1 px-2">
			{index !== 0 && (
				<CustomButton
					iconName="arrow-up"
					onClick={() => onChangeIndex(index, true)}
				/>
			)}
			{numOfPreset - 1 !== index ? (
				<CustomButton
					iconName="arrow-down"
					onClick={() => onChangeIndex(index, false)}
				/>
			) : (
				<div className="h-4 w-4"></div>
			)}
			<CustomButton
				iconName="bin"
				onClick={() => deletePreset(index)}
			/>
		</div>
	);
};
