import hotkeyBindings from './hotkeyBindings';
import windowLevelPresets from './windowLevelPresets';
export { hotkeyBindings, windowLevelPresets };

const DefaultConfig = {
	hotkeyBindings,
	windowLevelPresets
};

export default DefaultConfig;
