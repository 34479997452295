import { Enums } from '@cornerstonejs/core';

enum ExtendViewportType {
	VOLUME = 'volume',
	REMOTE_RENDER = 'remote-render'
}

const ViewportTypes = {
	...Enums.ViewportType,
	...ExtendViewportType
};

export default ViewportTypes;
