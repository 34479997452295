// @ts-nocheck

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Icon from '../Icon';
import Label from '../Label';

const baseInputClasses =
	'shadow transition duration-300 appearance-none border border-common-light focus:border-primary-light hover:border-primary-light focus:outline-none disabled:border-inputfield-disabled rounded w-full py-2 px-3 text-sm text-common-light placeholder-inputfield-placeholder leading-tight';

const transparentClasses = {
	true: 'bg-transparent',
	false: 'bg-black'
};

const smallInputClasses = {
	true: 'input-small',
	false: ''
};

const Input = ({
	id,
	label = '',
	containerClassName = '',
	labelClassName = '',
	className = '',
	transparent = false,
	smallInput = false,
	type = 'text',
	value = undefined,
	onChange = (event: React.FormEvent<HTMLInputElement>) => undefined,
	onFocus = (event) => undefined,
	autoFocus = false,
	onKeyPress = (event) => undefined,
	onKeyDown = (event) => undefined,
	readOnly = false,
	disabled = false,
	isFlexRow = false,
	...otherProps
}) => {
	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	return (
		<div className={classnames('flex flex-1', isFlexRow ? 'flex-row items-center space-x-4' : 'flex-col', containerClassName)}>
			<Label
				className={labelClassName}
				text={label}
			></Label>
			<div className="relative w-full">
				<input
					data-cy={`input-${id}`}
					className={classnames(
						label && !isFlexRow && 'mt-2',
						className,
						baseInputClasses,
						transparentClasses[transparent],
						smallInputClasses[smallInput],
						{
							'cursor-not-allowed': disabled
						}
					)}
					disabled={disabled}
					readOnly={readOnly}
					autoFocus={autoFocus}
					type={type === 'password' && showPassword ? 'text' : type}
					value={value}
					onChange={onChange}
					onFocus={onFocus}
					onKeyPress={onKeyPress}
					onKeyDown={onKeyDown}
					{...otherProps}
				/>
				{type === 'password' && (
					<div className="absolute inset-y-0 right-0 flex items-center pr-2 leading-5">
						<Icon
							name={showPassword ? 'eye-hidden' : 'eye-visible'}
							onClick={togglePasswordVisibility}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

Input.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	containerClassName: PropTypes.string,
	labelClassName: PropTypes.string,
	className: PropTypes.string,
	transparent: PropTypes.bool,
	smallInput: PropTypes.bool,
	type: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	autoFocus: PropTypes.bool,
	readOnly: PropTypes.bool,
	onKeyPress: PropTypes.func,
	onKeyDown: PropTypes.func,
	disabled: PropTypes.bool,
	'data-cy': PropTypes.string,
	isFlexRow: PropTypes.bool
};

export default Input;
