import PropTypes from 'prop-types';
import React from 'react';

import CustomDateRangePicker from '../DateRangePicker';
import { CustomDateRangePickerValue } from '../DateRangePicker/CustomDateRangePicker';
import InputLabelWrapper from '../InputLabelWrapper';

const InputDateRange = ({ id, label, isSortable, sortDirection, onLabelClick = noop, value = {}, onChange }) => {
	const { startDate, endDate } = value as CustomDateRangePickerValue;
	const onClickHandler = (event) => {
		event.preventDefault();
		onLabelClick(event);
	};

	return (
		<InputLabelWrapper
			label={label}
			isSortable={isSortable}
			sortDirection={sortDirection}
			onLabelClick={onClickHandler}
		>
			<div className="absolute">
				<CustomDateRangePicker
					value={{ startDate, endDate }}
					onChange={onChange}
				/>
			</div>
		</InputLabelWrapper>
	);
};

const noop = (..._args) => undefined;

InputDateRange.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string.isRequired,
	isSortable: PropTypes.bool.isRequired,
	sortDirection: PropTypes.oneOf(['ascending', 'descending', 'none']).isRequired,
	onLabelClick: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.any
};

export default InputDateRange;
