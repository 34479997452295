import { useEffect, useMemo, useState } from 'react';

import { ServicesManager } from '@core';
import localforage from 'config/localForageConfig';
import { LocalForageKey, Preset } from 'types';
import { useGetDisplaysetModality } from './useGetDisplaysetModality';

export const useGetPresetData = (serviceManager: ServicesManager, isGetPresetByModality: boolean) => {
	const modality = useGetDisplaysetModality(serviceManager);
	const { dataTrackingService } = serviceManager.services;
	const [presetData, setPresetData] = useState<Preset[]>([]);

	useEffect(() => {
		const getPreset = async () => {
			const currentPresets = ((await localforage.getItem(LocalForageKey['Preset'])) as Preset[]) ?? [];
			setPresetData(currentPresets);
		};

		const { unsubscribe } = dataTrackingService.subscribe(dataTrackingService.EVENTS.PRESET_DATA_CHANGED, ({ id }) => {
			getPreset();
		});

		return () => {
			unsubscribe();
		};
	}, [dataTrackingService]);

	useEffect(() => {
		const getPreset = async () => {
			const currentPresets = ((await localforage.getItem(LocalForageKey['Preset'])) as Preset[]) ?? [];
			setPresetData(currentPresets);
		};
		getPreset();
	}, []);

	const presetByModality = useMemo(() => {
		if (isGetPresetByModality) {
			return presetData.filter((item) => {
				if (item.modality === 'DX' && (modality === 'CR' || modality === 'DR')) {
					return true;
				}
				return item.modality.includes(modality) || item.modality === 'ALL';
			});
		}
		return presetData;
	}, [isGetPresetByModality, modality, presetData]);

	return presetByModality;
};
