import CommandsManager from './CommandsManager';
import HotkeysManager from './HotkeysManager';
import ImageSet from './ImageSet';
import MetadataProvider from './MetadataProvider';

export { MetadataProvider, CommandsManager, HotkeysManager, ImageSet };

const classes = {
	MetadataProvider,
	CommandsManager,
	HotkeysManager,
	ImageSet
};

export default classes;
