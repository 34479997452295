enum RPC_COMMANDS {
	CreateVisualization = 'volume.create',
	ResetCamera = 'volume.reset',
	MouseZoomWheel = 'viewport.mouse.zoom.wheel',
	Preset = 'volume.preset',
	Length = 'volume.length',
	Rotate = 'volume.rotate',
	Angle = 'volume.angle',
	DeleteAnnotation = 'volume.delete',
	Pan = 'volume.pan',
	Crop = 'volume.crop',
	CropFreehand = 'volume.crop.freehand',
	ViewPlane = 'volume.view.plane',
	ViewRevert = 'volume.revert.view.plane',
	// window level
	Shift = 'volume.shift',
	RemoveBed = 'volume.remove.bed',
	Zoom = 'volume.zoom',
	VolumeStatus = 'volume.status',
	SwitchSeries = 'volume.recreate'
}

enum VIEW_OPTIONS {
	ANTERIOR = 'ANTERIOR',
	SUPERIOR = 'SUPERIOR',
	INFERIOR = 'INFERIOR',
	LEFT = 'LEFT',
	RIGHT = 'RIGHT',
	POSTERIOR = 'POSTERIOR'
}

export { RPC_COMMANDS, VIEW_OPTIONS };
