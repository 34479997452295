import classnames from 'classnames';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { HotkeysManager, ServicesManager, hotkeys, utils } from '@core';
import { ComponentCustomization } from '@core/types';
import { useClientLink, useDebounce, useSearchParams } from '@hooks';
import { useAppConfig } from '@state';
import {
	AboutModal,
	EmptyStudies,
	Header,
	Icon,
	LegacyButton,
	LoadingIndicatorProgress,
	StudyListExpandedRow,
	StudyListFilter,
	StudyListPagination,
	StudyListTable,
	TooltipClipboard,
	UserPreferences,
	useModal
} from '@ui';
import filtersMeta from './filtersMeta';

const { sortBySeriesDate } = utils;

const seriesInStudiesMap = new Map();

interface WorkListProps {
	data: any[];
	dataTotal: number;
	isLoadingData: boolean;
	dataSource: any;
	onRefresh: () => void;
}

/**
 * TODO:
 * - debounce `setFilterValues` (150ms?)
 */
function WorkList({
	data: studies,
	dataTotal: studiesTotal,
	isLoadingData,
	dataSource,
	hotkeysManager,
	// dataPath,
	onRefresh = () => undefined,
	servicesManager,
	extensionManager
}: withAppTypes<WorkListProps>) {
	const { customizationService, sessionAuthenticationService } = servicesManager.services;
	const { hotkeyDefinitions, hotkeyDefaults } = hotkeysManager;
	const { show, hide } = useModal();
	const { t, i18n } = useTranslation();
	const availableLanguages = i18n['availableLanguages'];
	const defaultLanguage = i18n['defaultLanguage'];
	const currentLanguage = i18n['currentLanguage'];
	// ~ Modes
	const appConfig = useAppConfig();
	// ~ Filters
	const searchParams = useSearchParams();
	const navigate = useNavigate();
	const STUDIES_LIMIT = 101;
	const queryFilterValues = _getQueryFilterValues(searchParams);
	const [filterValues, _setFilterValues] = useState({
		...defaultFilterValues,
		...queryFilterValues
	});
	const { openViewer } = useClientLink();
	const debouncedFilterValues = useDebounce(filterValues, 200);
	const { resultsPerPage, pageNumber, sortBy, sortDirection } = filterValues;

	/*
	 * The default sort value keep the filters synchronized with runtime conditional sorting
	 * Only applied if no other sorting is specified and there are less than 101 studies
	 */

	const canSort = studiesTotal < STUDIES_LIMIT;
	const shouldUseDefaultSort = sortBy === '' || !sortBy;
	const sortModifier = sortDirection === 'descending' ? 1 : -1;
	const defaultSortValues = shouldUseDefaultSort && canSort ? { sortBy: 'studyDate', sortDirection: 'ascending' } : {};
	const sortedStudies = studies;

	if (canSort) {
		studies.sort((s1, s2) => {
			if (shouldUseDefaultSort) {
				const ascendingSortModifier = -1;
				return _sortStringDates(s1, s2, ascendingSortModifier);
			}

			const s1Prop = s1[sortBy];
			const s2Prop = s2[sortBy];

			if (typeof s1Prop === 'string' && typeof s2Prop === 'string') {
				return s1Prop.localeCompare(s2Prop) * sortModifier;
			} else if (typeof s1Prop === 'number' && typeof s2Prop === 'number') {
				return (s1Prop > s2Prop ? 1 : -1) * sortModifier;
			} else if (!s1Prop && s2Prop) {
				return -1 * sortModifier;
			} else if (!s2Prop && s1Prop) {
				return 1 * sortModifier;
			} else if (sortBy === 'studyDate') {
				return _sortStringDates(s1, s2, sortModifier);
			}

			return 0;
		});
	}

	// ~ Rows & Studies
	const [expandedRows, setExpandedRows] = useState([]);
	const [studiesWithSeriesData, setStudiesWithSeriesData] = useState([]);
	const numOfStudies = studiesTotal;
	const querying = useMemo(() => {
		return isLoadingData || expandedRows.length > 0;
	}, [isLoadingData, expandedRows]);

	const setFilterValues = (val) => {
		if (filterValues.pageNumber === val.pageNumber) {
			val.pageNumber = 1;
		}
		_setFilterValues(val);
		setExpandedRows([]);
	};

	const onPageNumberChange = (newPageNumber) => {
		const oldPageNumber = filterValues.pageNumber;
		const rollingPageNumberMod = Math.floor(101 / filterValues.resultsPerPage);
		const rollingPageNumber = oldPageNumber % rollingPageNumberMod;
		const isNextPage = newPageNumber > oldPageNumber;
		const hasNextPage = Math.max(rollingPageNumber, 1) * resultsPerPage < numOfStudies;

		if (isNextPage && !hasNextPage) {
			return;
		}

		setFilterValues({ ...filterValues, pageNumber: newPageNumber });
	};

	const onResultsPerPageChange = (newResultsPerPage) => {
		setFilterValues({
			...filterValues,
			pageNumber: 1,
			resultsPerPage: Number(newResultsPerPage)
		});
	};

	/**
	 * cần RESET Session ở màn Worklist
	 * để khi mở 1 studies, không bị conflict session với session cũ
	 */
	useEffect(() => {
		sessionAuthenticationService.reset();
	}, [sessionAuthenticationService]);

	// Set body style
	useEffect(() => {
		document.body.classList.add('bg-black');
		return () => {
			document.body.classList.remove('bg-black');
		};
	}, []);

	// Sync URL query parameters with filters
	useEffect(() => {
		if (!debouncedFilterValues) {
			return;
		}

		const queryString = {
			startDate: undefined,
			endDate: undefined,
			modalities: undefined
		};

		Object.keys(defaultFilterValues).forEach((key) => {
			const defaultValue = defaultFilterValues[key];
			const currValue = debouncedFilterValues[key];

			// TODO: nesting/recursion?
			if (key === 'studyDate') {
				if (currValue.startDate && defaultValue.startDate !== currValue.startDate) {
					queryString.startDate = currValue.startDate;
				}
				if (currValue.endDate && defaultValue.endDate !== currValue.endDate) {
					queryString.endDate = currValue.endDate;
				}
			} else if (key === 'modalities' && currValue.length) {
				queryString.modalities = currValue.join(',');
			} else if (currValue !== defaultValue) {
				queryString[key] = currValue;
			}
		});

		const search = qs.stringify(queryString, {
			skipNull: true,
			skipEmptyString: true
		});

		navigate({
			pathname: '/',
			search: search ? `?${search}` : undefined
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedFilterValues]);

	// Query for series information
	useEffect(() => {
		const fetchSeries = async (studyInstanceUid) => {
			try {
				const series = await dataSource.query.series.search(studyInstanceUid);
				seriesInStudiesMap.set(studyInstanceUid, sortBySeriesDate(series));
				setStudiesWithSeriesData([...studiesWithSeriesData, studyInstanceUid]);
			} catch (ex) {
				// TODO: UI Notification Service
				console.warn(ex);
			}
		};

		// TODO: WHY WOULD YOU USE AN INDEX OF 1?!
		// Note: expanded rows index begins at 1
		for (let z = 0; z < expandedRows.length; z++) {
			const expandedRowIndex = expandedRows[z] - 1;
			const studyInstanceUid = sortedStudies[expandedRowIndex].studyInstanceUid;

			if (studiesWithSeriesData.includes(studyInstanceUid)) {
				continue;
			}

			fetchSeries(studyInstanceUid);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expandedRows, studies]);

	const isFiltering = (filterValues, defaultFilterValues) => {
		return !isEqual(filterValues, defaultFilterValues);
	};

	const rollingPageNumberMod = Math.floor(101 / resultsPerPage);
	const rollingPageNumber = (pageNumber - 1) % rollingPageNumberMod;
	const offset = resultsPerPage * rollingPageNumber;
	const offsetAndTake = offset + resultsPerPage;
	const tableDataSource = sortedStudies.map((study, key) => {
		const rowKey = key + 1;
		const isExpanded = expandedRows.some((k) => k === rowKey);
		const { studyInstanceUid, accession, modalities, instances, description, mrn, patientName, date, time } = study;
		const studyDate =
			date &&
			moment(date, ['YYYYMMDD', 'YYYY.MM.DD'], true).isValid() &&
			moment(date, ['YYYYMMDD', 'YYYY.MM.DD']).format('MMM-DD-YYYY');
		const studyTime =
			time &&
			moment(time, ['HH', 'HHmm', 'HHmmss', 'HHmmss.SSS']).isValid() &&
			moment(time, ['HH', 'HHmm', 'HHmmss', 'HHmmss.SSS']).format('hh:mm A');

		return {
			row: [
				{
					key: 'patientName',
					content: patientName ? (
						<TooltipClipboard>{patientName}</TooltipClipboard>
					) : (
						<span className="text-gray-700">(Empty)</span>
					),
					gridCol: 4
				},
				{
					key: 'mrn',
					content: <TooltipClipboard>{mrn}</TooltipClipboard>,
					gridCol: 3
				},
				{
					key: 'studyDate',
					content: (
						<>
							{studyDate && <span className="mr-4">{studyDate}</span>}
							{studyTime && <span>{studyTime}</span>}
						</>
					),
					title: `${studyDate || ''} ${studyTime || ''}`,
					gridCol: 5
				},
				{
					key: 'description',
					content: <TooltipClipboard>{description}</TooltipClipboard>,
					gridCol: 4
				},
				{
					key: 'modality',
					content: modalities,
					title: modalities,
					gridCol: 3
				},
				{
					key: 'accession',
					content: <TooltipClipboard>{accession}</TooltipClipboard>,
					gridCol: 3
				},
				{
					key: 'instances',
					content: (
						<>
							<Icon
								name="group-layers"
								className={classnames('mr-2 inline-flex w-4', {
									'text-primary-active': isExpanded,
									'text-secondary-light': !isExpanded
								})}
							/>
							{instances}
						</>
					),
					title: (instances || 0).toString(),
					gridCol: 4
				}
			],
			// Todo: This is actually running for all rows, even if they are
			// not clicked on.
			expandedContent: (
				<StudyListExpandedRow
					key={rowKey}
					seriesTableColumns={{
						description: 'Description',
						seriesNumber: 'Series',
						modality: 'Modality',
						instances: 'Instances'
					}}
					seriesTableDataSource={
						seriesInStudiesMap.has(studyInstanceUid)
							? seriesInStudiesMap.get(studyInstanceUid).map((s) => {
									return {
										description: s.description || '(empty)',
										seriesNumber: s.seriesNumber ?? '',
										modality: s.modality || '',
										instances: s.numSeriesInstances || ''
									};
								})
							: []
					}
				>
					<div className="flex flex-row gap-2">
						{appConfig.loadedModes.map((mode) => {
							const modalitiesToCheck = modalities.replaceAll('/', '\\');

							const isValidMode = mode.isValidMode({
								modalities: modalitiesToCheck,
								study
							});
							// TODO: Modes need a default/target route? We mostly support a single one for now.
							// We should also be using the route path, but currently are not
							// mode.routeName
							// mode.routes[x].path
							// Don't specify default data source, and it should just be picked up... (this may not currently be the case)
							// How do we know which params to pass? Today, it's just StudyInstanceUIDs and configUrl if exists
							const query = new URLSearchParams();
							if (filterValues.configUrl) {
								query.append('configUrl', filterValues.configUrl);
							}
							query.append('StudyInstanceUIDs', studyInstanceUid);
							return (
								mode.displayName && (
									<Fragment key={mode.displayName}>
										{/* TODO revisit the completely rounded style of buttons used for launching a mode from the worklist later - for now use LegacyButton*/}
										<LegacyButton
											rounded="full"
											className={isValidMode ? '' : 'cursor-not-allowed'}
											variant={isValidMode ? 'contained' : 'disabled'}
											disabled={!isValidMode}
											endIcon={<Icon name="launch-arrow" />} // launch-arrow | launch-info
											onClick={() => openViewer(studyInstanceUid)}
										>
											{t(`Modes:${mode.displayName}`)}
										</LegacyButton>
									</Fragment>
								)
							);
						})}
					</div>
				</StudyListExpandedRow>
			),
			onClickRow: () => setExpandedRows((s) => (isExpanded ? s.filter((n) => rowKey !== n) : [...s, rowKey])),
			isExpanded
		};
	});

	const hasStudies = numOfStudies > 0;
	const commitHash = process.env.COMMIT_HASH;

	const menuOptions = [
		{
			title: t('Header:About'),
			icon: 'info',
			onClick: () =>
				show({
					content: AboutModal,
					title: 'About SAOLA Viewer',
					contentProps: { commitHash }
				})
		},
		{
			title: t('Header:Preferences'),
			icon: 'settings',
			onClick: () =>
				show({
					title: t('UserPreferencesModal:User Preferences'),
					content: UserPreferences,
					contentProps: {
						hotkeyDefaults: hotkeysManager.getValidHotkeyDefinitions(hotkeyDefaults),
						hotkeyDefinitions,
						onCancel: hide,
						currentLanguage: currentLanguage(),
						availableLanguages,
						defaultLanguage,
						onSubmit: (state) => {
							if (state.language.value !== currentLanguage().value) {
								i18n.changeLanguage(state.language.value);
							}
							hotkeysManager.setHotkeys(state.hotkeyDefinitions);
							hide();
						},
						onReset: () => hotkeysManager.restoreDefaultBindings(),
						hotkeysModule: hotkeys
					}
				})
		}
	];

	if (appConfig.oidc) {
		menuOptions.push({
			icon: 'power-off',
			title: t('Header:Logout'),
			onClick: () => {
				navigate(`/logout?redirect_uri=${encodeURIComponent(window.location.href)}`);
			}
		});
	}

	const { component: dicomUploadComponent } = (customizationService.get('dicomUploadComponent') ?? {}) as {
		component: React.ComponentType;
	};
	const uploadProps =
		dicomUploadComponent && dataSource.getConfig()?.dicomUploadEnabled
			? {
					title: 'Upload files',
					closeButton: true,
					shouldCloseOnEsc: false,
					shouldCloseOnOverlayClick: false,
					content: dicomUploadComponent.bind(null, {
						dataSource,
						onComplete: () => {
							hide();
							onRefresh();
						},
						onStarted: () => {
							show({
								...uploadProps,
								// when upload starts, hide the default close button as closing the dialogue must be handled by the upload dialogue itself
								closeButton: false
							});
						}
					})
				}
			: undefined;

	const { component: dataSourceConfigurationComponent } = (customizationService.get('ohif.dataSourceConfigurationComponent') ??
		{}) as ComponentCustomization;

	return (
		<div className="flex h-screen flex-col bg-black ">
			<Header
				isSticky
				menuOptions={menuOptions}
				isReturnEnabled={false}
				WhiteLabeling={appConfig.whiteLabeling}
				extensionManager={extensionManager}
			/>
			<div className="saola-scrollbar flex grow flex-col overflow-y-auto">
				<StudyListFilter
					numOfStudies={pageNumber * resultsPerPage > 100 ? 101 : numOfStudies}
					filtersMeta={filtersMeta}
					filterValues={{ ...filterValues, ...defaultSortValues }}
					onChange={setFilterValues}
					clearFilters={() => setFilterValues(defaultFilterValues)}
					isFiltering={isFiltering(filterValues, defaultFilterValues)}
					onUploadClick={uploadProps ? () => show(uploadProps) : undefined}
					getDataSourceConfigurationComponent={
						dataSourceConfigurationComponent
							? () => {
									return <>(dataSourceConfigurationComponent)</>;
								}
							: undefined
					}
				/>
				{hasStudies ? (
					<div className="flex grow flex-col">
						<StudyListTable
							tableDataSource={tableDataSource.slice(offset, offsetAndTake)}
							numOfStudies={numOfStudies}
							querying={querying}
							filtersMeta={filtersMeta}
						/>
						<div className="grow">
							<StudyListPagination
								onChangePage={onPageNumberChange}
								onChangePerPage={onResultsPerPageChange}
								currentPage={pageNumber}
								perPage={resultsPerPage}
							/>
						</div>
					</div>
				) : (
					<div className="flex flex-col items-center justify-center pt-48">
						{appConfig.showLoadingIndicator && isLoadingData ? (
							<LoadingIndicatorProgress className={'h-full w-full bg-black'} />
						) : (
							<EmptyStudies />
						)}
					</div>
				)}
			</div>
		</div>
	);
}
WorkList.propTypes = {
	data: PropTypes.array.isRequired,
	dataSource: PropTypes.shape({
		query: PropTypes.object.isRequired,
		getConfig: PropTypes.func
	}).isRequired,
	dataTotal: PropTypes.number,
	onRefresh: PropTypes.func,
	isLoadingData: PropTypes.bool.isRequired,
	servicesManager: PropTypes.instanceOf(ServicesManager),
	hotkeysManager: PropTypes.instanceOf(HotkeysManager)
};

const defaultFilterValues = {
	patientName: '',
	mrn: '',
	studyDate: {
		startDate: null,
		endDate: null
	},
	description: '',
	modalities: [],
	accession: '',
	sortBy: '',
	sortDirection: 'none',
	pageNumber: 1,
	resultsPerPage: 25,
	datasources: '',
	configUrl: null
};

function _tryParseInt(str, defaultValue) {
	let retValue = defaultValue;
	if (str && str.length > 0) {
		if (!isNaN(str)) {
			retValue = parseInt(str);
		}
	}
	return retValue;
}

function _getQueryFilterValues(params) {
	const queryFilterValues = {
		patientName: params.get('patientname'),
		mrn: params.get('mrn'),
		studyDate: {
			startDate: params.get('startdate') || null,
			endDate: params.get('enddate') || null
		},
		description: params.get('description'),
		modalities: params.get('modalities') ? params.get('modalities').split(',') : [],
		accession: params.get('accession'),
		sortBy: params.get('sortby'),
		sortDirection: params.get('sortdirection'),
		pageNumber: _tryParseInt(params.get('pagenumber'), undefined),
		resultsPerPage: _tryParseInt(params.get('resultsperpage'), undefined),
		datasources: params.get('datasources'),
		configUrl: params.get('configurl')
	};

	// Delete null/undefined keys
	Object.keys(queryFilterValues).forEach((key) => queryFilterValues[key] == null && delete queryFilterValues[key]);

	return queryFilterValues;
}

function _sortStringDates(s1, s2, sortModifier) {
	// TODO: Delimiters are non-standard. Should we support them?
	const s1Date = moment(s1.date, ['YYYYMMDD', 'YYYY.MM.DD'], true);
	const s2Date = moment(s2.date, ['YYYYMMDD', 'YYYY.MM.DD'], true);

	if (s1Date.isValid() && s2Date.isValid()) {
		return (s1Date.toISOString() > s2Date.toISOString() ? 1 : -1) * sortModifier;
	} else if (s1Date.isValid()) {
		return sortModifier;
	} else if (s2Date.isValid()) {
		return -1 * sortModifier;
	}
}

export default WorkList;
