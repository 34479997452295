import React from 'react';

import { useModal } from '../../contextProviders';
import ToolbarButton from '../ToolbarButton';
import QRScanModal from './QRScanModal';

const QRScanButton = () => {
	const { show } = useModal();

	return (
		<ToolbarButton
			icon={'qr-scanning'}
			label={'Share to phone'}
			onInteraction={() =>
				show({
					content: QRScanModal,
					title: 'Share to phone',
					customClassNameContent: 'bg-white',
					paddingContent: 'py-2 px-2'
				})
			}
			id=""
			dropdownContent=""
			type="action"
		/>
	);
};
export default QRScanButton;
