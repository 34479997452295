enum type {
	primary = 'primary',
	secondary = 'secondary'
}
enum size {
	medium = 'medium',
	small = 'small'
}

enum orientation {
	horizontal = 'horizontal',
	vertical = 'vertical'
}

export { type, size, orientation };
