import React from 'react';

export const CannotViewImages = () => {
	return (
		<div className="grid items-center gap-2 text-xs font-light">
			<div className="text-sm">
				<div className="text-common-light">Please try again or contact Administrators.</div>
			</div>
			{/* <div className="flex flex-row-reverse gap-2">
				<button
					className="w-20 rounded-[2px] border border-primary-light bg-primary-light text-sm text-common-light hover:border-primary-active hover:bg-primary-active"
					onClick={() => {
						window.location.reload();
					}}
				>
					Try again
				</button>
			</div> */}
		</div>
	);
};
