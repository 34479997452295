import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const TableCell = ({ children, className = '', colSpan = 1, cellsNum = 0, isTableHead = false, align = 'left', style = {} }) => {
	const classes = {
		align: {
			left: 'text-left',
			center: 'text-center',
			right: 'text-right',
			justify: 'text-justify'
		},
		isTableHead: {
			true: '',
			false: 'border-r border-secondary-light'
		}
	};

	return (
		<div
			className={classnames(
				'break-all px-2 last:border-r-0',
				`w-${colSpan}/${cellsNum}`,
				classes.align[align],
				classes.isTableHead[isTableHead.toString()],
				className
			)}
			style={style}
		>
			{children}
		</div>
	);
};

TableCell.propTypes = {
	align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.number]),
	className: PropTypes.string,
	colSpan: PropTypes.number,
	isTableHead: PropTypes.bool,
	style: PropTypes.object,
	cellsNum: PropTypes.number
};

export default TableCell;
