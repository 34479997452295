const createClickOutsideHandler = (element: React.RefObject<HTMLElement>, onClickOutside: () => void) => {
	const clickOutsideHandler = (event: MouseEvent) => {
		if (element.current && !element.current.contains(event.target as Node)) {
			onClickOutside();
			if (typeof window !== 'undefined') {
				window.removeEventListener('mousedown', clickOutsideHandler);
			}
		}
	};

	const add = () => {
		if (typeof window !== 'undefined') {
			window.addEventListener('mousedown', clickOutsideHandler);
		}
	};

	const remove = () => {
		if (typeof window !== 'undefined') {
			window.removeEventListener('mousedown', clickOutsideHandler);
		}
	};

	return {
		add,
		remove
	};
};

export default createClickOutsideHandler;
