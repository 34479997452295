import classnames from 'classnames';
import React, { FC, ReactNode } from 'react';

import { Commands } from '@core/types';
import Icon from '../Icon';
import IconButton from '../IconButton';
import Tooltip from '../Tooltip';

type ToolbarButtonProps = {
	type: 'action' | 'toggle' | 'tool' | 'primary';
	id: string;
	isActive?: boolean;
	shortcutKey?: string;
	className?: string;
	tooltipClassName?: string;
	commands?: Commands;
	onInteraction?: (options) => void;
	icon?: string;
	label?: string;
	/** Tooltip content can be replaced for a customized content by passing a node to this value. */
	dropdownContent?: ReactNode | FC;
	disabled?: boolean;
	size?: 'toolbar' | 'quickToolbar';
};

const ToolbarButton: FC<ToolbarButtonProps> = ({
	type = 'action',
	id,
	icon,
	label,
	shortcutKey,
	commands,
	onInteraction,
	dropdownContent = null,
	isActive = false,
	className,
	size = 'toolbar',
	...rest
	//
}) => {
	const classes = {
		tool: isActive ? 'text-black' : 'text-common-bright hover:!bg-primary-light hover:text-primary-light',
		toggle: isActive ? 'text-black' : 'text-common-bright hover:!bg-primary-light hover:text-primary-light',
		action: isActive ? 'text-black' : 'text-common-bright hover:!bg-primary-light hover:text-primary-light'
	};
	const bgClasses = {
		toggle: isActive && '!bg-primary-active',
		tool: isActive && '!bg-primary-active'
	};

	const activeClass = isActive ? 'active' : '';
	const shouldShowDropdown = !!isActive && !!dropdownContent;
	const iconEl = icon ? <Icon name={icon} /> : <div>{label || 'Missing icon and label'}</div>;
	const execLabel = shortcutKey && label ? `${label} (${shortcutKey})` : label;

	return (
		<div
			key={id}
			className="relative"
		>
			<Tooltip
				isSticky={shouldShowDropdown}
				content={shouldShowDropdown ? dropdownContent : execLabel}
				tight={shouldShowDropdown}
			>
				{/* {isActive && <span className="absolute left-4 z-10 h-1 w-4 bg-orange-custom" />} */}
				<IconButton
					variant={isActive ? 'contained' : 'text'}
					bgColor={bgClasses[type]}
					size={size}
					className={classnames(activeClass, classes[type], className)}
					onClick={() => {
						onInteraction({
							itemId: id,
							interactionType: type,
							commands
						});
					}}
					name={label}
					key={id}
					id={id}
					{...rest}
				>
					{iconEl}
				</IconButton>
			</Tooltip>
		</div>
	);
};

export default ToolbarButton;
