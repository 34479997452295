import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';
import ListMenu from '../ListMenu';
import Tooltip from '../Tooltip';

const baseClasses = {
	Button: 'flex items-center border-transparent cursor-pointer group/button',
	Primary:
		// By default border on left, top and bottom for hover effect and only rounded on left side.
		// Extra padding on right to compensate for no right border.
		'h-full group/primary',
	Secondary: 'h-full flex items-center justify-center w-4 border-2 border-transparent py-4 group/secondary hover:bg-primary-light',
	SecondaryIcon: 'w-4 h-full stroke-1',
	Separator: 'border-l py-2.5',
	Content: 'absolute z-10 top-0 mt-12'
};

const classes = {
	Button: classNames(baseClasses.Button),
	Interface: 'h-full flex flex-row items-center',
	Primary: ({ isActive, isExpanded, isToggle }) =>
		classNames(
			baseClasses.Primary,
			isActive
				? isExpanded
					? '!bg-primary-active hover:border-primary-light !text-primary-light'
					: `${isToggle ? 'border-secondary-dark bg-secondary-light' : 'border-primary-light bg-primary-active'}
            ` // Full, rounded border with less right padding when active.
				: `focus:!text-black  focus:!border-primary-light focus:!bg-primary-light
        ${
			isExpanded &&
			'border-secondary-dark bg-primary-main group-hover/button:border-primary-dark group-hover/button:text-primary-light hover:!bg-primary-light focus:!text-black'
		}
        `
		),
	Secondary: ({ isExpanded, primary }) =>
		classNames(
			baseClasses.Secondary,
			isExpanded ? 'bg-primary-light' : primary.isActive ? 'bg-primary-main' : 'hover:bg-primary-dark bg-primary-main group-hover'
		),
	SecondaryIcon: classNames(baseClasses.SecondaryIcon, 'text-white'),
	Separator: ({ primary, isExpanded, isHovering }) =>
		classNames(baseClasses.Separator, isHovering || isExpanded || primary.isActive ? 'border-transparent' : 'border-primary-active'),
	Content: ({ isExpanded }) => classNames(baseClasses.Content, isExpanded ? 'block' : 'hidden')
};

const DefaultListItemRenderer = (props) => {
	const { t, icon, label, className, isActive = false, shortcutKey } = props;
	// console.log('propspropsprops', props);

	return (
		<div
			className={classNames(
				'flex h-7 w-full flex-row items-center px-2 text-sm',
				'whitespace-pre bg-primary-dark text-sm font-normal hover:bg-primary-light',
				isActive && 'bg-primary-light'
			)}
		>
			{icon && (
				<span className="mr-4">
					<Icon
						name={icon}
						className="h-6 w-6 text-common-light"
					/>
				</span>
			)}
			<span className="w-full flex-1">{t(label)}</span>
			{shortcutKey && <span className="ml-5">{`(${shortcutKey})`}</span>}
		</div>
	);
};

/**
 * This is a more generic version of SplitButton without the isActive
 * and other interaction props
 */
const SplitButton = ({
	groupId,
	primary,
	secondary,
	items,
	renderer = DefaultListItemRenderer,
	onInteraction,
	Component = null,
	isToggle = false
}) => {
	const { t } = useTranslation('Buttons');
	const [state, setState] = useState({ isHovering: false, isExpanded: false });

	const toggleExpanded = useCallback(() => setState({ ...state, isExpanded: !state.isExpanded }), [state]);
	const setHover = useCallback((hovering) => setState({ ...state, isHovering: hovering }), [state]);
	const collapse = useCallback(() => setState({ ...state, isExpanded: false }), [state]);

	const listItemRenderer = renderer;
	const primaryClassNames = classNames(
		classes.Primary({
			isExpanded: state.isExpanded,
			isActive: primary.isActive,
			isToggle
		}),
		primary.className
	);

	const onPrimaryClick = useCallback(
		(e) => {
			if (!primary.commands) {
				setHover(true);
				toggleExpanded();
			} else {
				collapse();
				onInteraction(e);
			}
		},
		[collapse, onInteraction, primary.commands, setHover, toggleExpanded]
	);

	return (
		<div onMouseLeave={collapse}>
			<div
				id="SplitButton"
				className="relative"
			>
				<div
					className={classes.Button}
					style={{ height: '48px' }}
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
				>
					<div className={classes.Interface}>
						<div>
							<Component
								key={primary.id}
								{...primary}
								onInteraction={onPrimaryClick}
								rounded="none"
								className={primaryClassNames}
								data-tool={primary.id}
								data-cy={`${groupId}-split-button-primary`}
							/>
						</div>
						{secondary && (
							<Tooltip
								isDisabled={state.isExpanded || !secondary.tooltip}
								content={secondary.tooltip}
								className="h-full"
							>
								<div
									className={classes.Secondary({ ...state, primary })}
									data-cy={`${groupId}-split-button-secondary`}
									onMouseEnter={toggleExpanded}
									onMouseLeave={collapse}
								>
									<Icon
										name={secondary.icon}
										className={classes.SecondaryIcon}
									/>
								</div>
							</Tooltip>
						)}
					</div>
				</div>
				<div
					className={classes.Content({ ...state })}
					data-cy={`${groupId}-list-menu`}
				>
					<ListMenu
						items={items}
						onClick={collapse}
						renderer={(args) => listItemRenderer({ ...args, t })}
					/>
				</div>
			</div>
		</div>
	);
};

SplitButton.propTypes = {
	isToggle: PropTypes.bool,
	groupId: PropTypes.string.isRequired,
	primary: PropTypes.object.isRequired,
	secondary: PropTypes.object.isRequired,
	items: PropTypes.array.isRequired,
	renderer: PropTypes.func,
	isActive: PropTypes.bool,
	onInteraction: PropTypes.func.isRequired,
	Component: PropTypes.elementType,
	interactionType: PropTypes.oneOf(['action', 'tool', 'toggle'])
};

export default SplitButton;
