import AboutModal from './AboutModal.json';
import Buttons from './Buttons.json';
import CineDialog from './CineDialog.json';
import Common from './Common.json';
import DataSourceConfiguration from './DataSourceConfiguration.json';
import DatePicker from './DatePicker.json';
import Header from './Header.json';
import MeasurementTable from './MeasurementTable.json';
import SidePanel from './SidePanel.json';
import StudyBrowser from './StudyBrowser.json';
import StudyList from './StudyList.json';
import UserPreferencesModal from './UserPreferencesModal.json';
import ViewportDownloadForm from './ViewportDownloadForm.json';
import Messages from './Messages.json';

const en_US = {
	'en-US': {
		AboutModal,
		Buttons,
		CineDialog,
		Common,
		DataSourceConfiguration,
		DatePicker,
		Header,
		MeasurementTable,
		SidePanel,
		StudyBrowser,
		StudyList,
		UserPreferencesModal,
		ViewportDownloadForm,
		Messages
	}
};

export default en_US;
