import React, { useEffect, useMemo } from 'react';

import getWindowLevelPresets from '@core/defaults/windowLevelPresets';
import { useGetDisplaysetModality } from '@hooks';
import { CustomPresets, ListMenu, WindowLevelMenuItem } from '@ui';
import { createWwwcPreset } from '@utils';
import { HotkeysManager } from '@core';

/**
 * Danh sách WL Preset hiển thị ở left panel
 */
export const PanelWLPreset = ({
	commandsManager,
	servicesManager,
	hotkeysManager
}: {
	hotkeysManager?: HotkeysManager;
	servicesManager;
	commandsManager;
}) => {
	const modality = useGetDisplaysetModality(servicesManager);
	const presets = useMemo(() => getWindowLevelPresets(modality), [modality]);

	/**
	 *Effect này update lại hotkeys theo danh sách preset ứng với từng loại máy
	 */
	useEffect(() => {
		if (presets && hotkeysManager) {
			const presetHotKeys = Object.keys(presets).map((presetKey) => {
				const preset = presets[presetKey];
				return {
					commandName: 'setWindowLevel',
					commandOptions: {
						window: parseInt(preset.window),
						level: parseInt(preset.level)
					},
					isEditable: false,
					keys: [(parseInt(presetKey) + 1).toString()]
				};
			});
			hotkeysManager.updateHotKeys(presetHotKeys);
		}
	}, [hotkeysManager, presets]);
	const items = useMemo(() => {
		const listItems = Object.values(presets || {}).map((item, index) => {
			return createWwwcPreset({ preset: item, type: 'NORMAL' });
		});
		listItems.unshift(createWwwcPreset({ type: 'PSEUDO', preset: { description: 'Pseudo Color', level: '', window: '' } }));
		return listItems;
	}, [presets]);

	const onClick = ({ item, selectedIndex: string }) => {
		commandsManager.run(item.commands);
	};

	return (
		<div className="saola-scrollbar invisible-scrollbar overflow-auto text-common-gray">
			<ListMenu
				items={items}
				renderer={WindowLevelMenuItem}
				className={'rounded-none border-none'}
				onClick={onClick}
				activeOptions={false}
			/>
			<div className="mx-3 border-t border-common-dark"></div>
			<CustomPresets
				commandsManager={commandsManager}
				servicesManager={servicesManager}
			/>
		</div>
	);
};
