import i18n from '@i18n';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import {
	CommandsManager,
	ExtensionManager,
	HotkeysManager,
	ServiceProvidersManager,
	ServicesManager,
	SessionAuthenticationProvider
} from '@core';
import { DialogProvider, Modal, ModalProvider, SnackbarProvider, ThemeWrapper, ViewportDialogProvider, ViewportGridProvider } from '@ui';

import Compose from './routes/Mode/Compose';
// Viewer Project
// TODO: Should this influence study list?
import { useDetectMobile } from '@hooks';
import appInit from './appInit';
import createRoutes from './routes';
import { AppConfigProvider } from './state';

let commandsManager: CommandsManager,
	extensionManager: ExtensionManager,
	servicesManager: ServicesManager,
	serviceProvidersManager: ServiceProvidersManager,
	hotkeysManager: HotkeysManager;

function App({
	config = {
		routerBaseName: '/',
		showLoadingIndicator: true,
		showStudyList: true,
		oidc: [],
		extensions: []
	},
	defaultExtensions = [],
	defaultModes = []
}) {
	const isTabletOrMobile = useDetectMobile();
	const [init, setInit] = useState(null);
	useEffect(() => {
		const run = async () => {
			appInit({ ...config, isTabletOrMobile }, defaultExtensions, defaultModes)
				.then(setInit)
				.catch(console.error);
		};

		run();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!init) {
		return null;
	}

	// Set above for named export
	commandsManager = init.commandsManager;
	extensionManager = init.extensionManager;
	servicesManager = init.servicesManager;
	serviceProvidersManager = init.serviceProvidersManager;
	hotkeysManager = init.hotkeysManager;

	// Set appConfig
	const appConfigState = init.appConfig;
	const { routerBasename, modes, dataSources, showStudyList } = appConfigState;

	const {
		uiDialogService,
		uiModalService,
		uiViewportDialogService,
		viewportGridService,
		sessionAuthenticationService,
		customizationService,
		uiNotificationService
	} = servicesManager.services;

	const providers = [];
	providers.push(
		[AppConfigProvider, { value: appConfigState }],
		[SessionAuthenticationProvider, { service: sessionAuthenticationService }],
		[I18nextProvider, { i18n }],
		[ThemeWrapper],
		[ViewportGridProvider, { service: viewportGridService }],
		[ViewportDialogProvider, { service: uiViewportDialogService }],
		[DialogProvider, { service: uiDialogService }],
		[
			ModalProvider,
			{
				service: uiModalService,
				modal: Modal
			}
		],
		[SnackbarProvider, { service: uiNotificationService }]
	);

	// Loop through and register each of the service providers registered with the ServiceProvidersManager.
	const providersFromManager = Object.entries(serviceProvidersManager.providers);
	if (providersFromManager.length > 0) {
		providersFromManager.forEach(([serviceName, provider]) => {
			providers.push([provider, { service: servicesManager.services[serviceName], commandsManager }]);
		});
	}

	const CombinedProviders = ({ children }) =>
		Compose({
			components: providers,
			children
		});

	// Should there be a generic call to init on the extension manager?
	customizationService.init(extensionManager);

	// Use config to create routes
	const appRoutes = createRoutes({
		modes,
		dataSources,
		extensionManager,
		servicesManager,
		commandsManager,
		hotkeysManager,
		routerBasename,
		showStudyList
	});

	return (
		<CombinedProviders>
			<BrowserRouter
				basename={routerBasename}
				future={{
					v7_startTransition: true,
					v7_relativeSplatPath: true
				}}
			>
				{appRoutes}
			</BrowserRouter>
		</CombinedProviders>
	);
}

App.propTypes = {
	config: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({
			routerBasename: PropTypes.string.isRequired,
			whiteLabeling: PropTypes.object,
			extensions: PropTypes.array
		})
	]).isRequired,
	/* Extensions that are "bundled" or "baked-in" to the application.
	 * These would be provided at build time as part of they entry point. */
	defaultExtensions: PropTypes.array,
	defaultModes: PropTypes.array
};

export default App;

export { commandsManager, extensionManager, servicesManager };
