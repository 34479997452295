import classnames from 'classnames';
import React, { ReactNode } from 'react';

const Label: React.FC<LabelProps> = ({ children, className, text, ...rest }) => {
	const baseClasses = '';

	return (
		<label
			className={classnames(baseClasses, className)}
			{...rest}
		>
			{text}
			{children}
		</label>
	);
};

interface LabelProps {
	children?: ReactNode;
	className?: string;
	text?: string;
}

export default Label;
