import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommandsManager, ExtensionManager, ServicesManager } from '@core';
import { isFusionable } from '@utils';
import { useViewportGrid } from '../../contextProviders';
import Dropdown from '../Dropdown';

enum ViewerPlugin {
	'Default' = 'default',
	'MPR' = 'mpr',
	'remoteRender3D' = 'remoteRender3D',
	'pt/ct' = 'pt/ct',
	'remoteRenderEndo' = 'remoteRenderEndo'
}

export const PluginSwitch = ({
	servicesManager,
	commandsManager,
	onMobile = false,
	extensionManager
}: {
	servicesManager: ServicesManager;
	commandsManager: CommandsManager;
	onMobile?: boolean;
	extensionManager?: ExtensionManager;
}) => {
	const { t } = useTranslation('Header');
	const type = onMobile ? t('ShortViewerPlugin', { returnObjects: true }) : t('ViewerPlugin', { returnObjects: true });

	// state dùng tạm
	const [currentMenu, setCurrentMenu] = useState<`${ViewerPlugin}`>(ViewerPlugin['Default']);
	const { displaySetService, hangingProtocolService } = servicesManager.services;
	const [{ viewports, activeViewportId }] = useViewportGrid();
	const activeViewport = viewports.get(activeViewportId);
	const displaySet =
		displaySetService &&
		activeViewport &&
		activeViewport.displaySetInstanceUIDs &&
		activeViewport.displaySetInstanceUIDs &&
		activeViewport.displaySetInstanceUIDs.length &&
		activeViewport.displaySetInstanceUIDs[0]
			? displaySetService.getDisplaySetByUID(activeViewport.displaySetInstanceUIDs[0])
			: undefined;

	const fusionable = useMemo(() => displaySet && isFusionable(extensionManager), [displaySet, extensionManager]);
	const switchToFusion = useCallback(() => {
		commandsManager.run({
			commandName: 'showModalSelectFusionSeries'
		});
	}, [commandsManager]);

	const switchToEndo = useCallback(() => {
		commandsManager.run({
			commandName: 'setHangingProtocol',
			commandOptions: {
				protocolId: 'remoteRenderEndo'
			}
		});
	}, [commandsManager]);

	const switchToMPR3D = useCallback(() => {
		commandsManager.run({
			commandName: 'setHangingProtocol',
			commandOptions: {
				protocolId: 'mpr'
				// stageIndex: 0
				// reset: true
			}
		});
	}, [commandsManager]);

	const switchTo2D = useCallback(() => {
		setCurrentMenu(ViewerPlugin['Default']);
		commandsManager.run({
			commandName: 'setHangingProtocol',
			commandOptions: {
				protocolId: 'default',
				isHangingProtocolLayout: false,
				initialImageOptions: {
					preset: 'middle'
				}
				// stageIndex: 0
				// reset: true
			}
		});
	}, [commandsManager]);

	const switchTo3D = useCallback(() => {
		setCurrentMenu(ViewerPlugin['remoteRender3D']);
		commandsManager.run({
			commandName: 'setHangingProtocol',
			commandOptions: {
				protocolId: 'remoteRender3D'
			}
		});
	}, [commandsManager]);

	const menuOptions = useMemo(
		() => [
			{
				title: type['2d'],
				onClick: switchTo2D,
				id: ViewerPlugin['Default'],
				active: currentMenu === ViewerPlugin['Default'],
				enabled: true
			},
			{
				title: type['mpr'],
				onClick: switchToMPR3D,
				id: ViewerPlugin['MPR'],
				active: currentMenu === ViewerPlugin['MPR'],
				enabled: displaySet?.isReconstructable
			},
			{
				title: type['3d'],
				onClick: switchTo3D,
				id: ViewerPlugin['remoteRender3D'],
				active: currentMenu === ViewerPlugin['remoteRender3D'],
				enabled: displaySet?.isReconstructable
			},
			{
				title: type['pt/ct'],
				onClick: switchToFusion,
				id: ViewerPlugin['pt/ct'],
				active: currentMenu === ViewerPlugin['pt/ct'],
				enabled: fusionable
			},
			{
				title: type['endo'],
				onClick: switchToEndo,
				id: ViewerPlugin['remoteRenderEndo'],
				active: currentMenu === ViewerPlugin['remoteRenderEndo'],
				enabled: displaySet?.isReconstructable
			}
		],
		[type, switchTo2D, currentMenu, switchToMPR3D, displaySet?.isReconstructable, switchTo3D, switchToFusion, fusionable, switchToEndo]
	);

	useEffect(() => {
		const { unsubscribe } = hangingProtocolService.subscribe(hangingProtocolService.EVENTS.PROTOCOL_CHANGED, ({ protocol }) => {
			setCurrentMenu(protocol.id);
		});

		return () => {
			unsubscribe();
		};
	}, [hangingProtocolService]);

	const title = useMemo(() => {
		return menuOptions.find((item) => item.id === currentMenu)?.title || '';
	}, [currentMenu, menuOptions]);

	return (
		<div>
			<Dropdown
				id="switch-viewers"
				showDropdownIcon={true}
				list={menuOptions}
				alignment="left"
				itemsClassName={'text-white h-7 text-xs'}
				showBorders={false}
			>
				<div className="mx-2 text-sm text-common-bright">{title}</div>
			</Dropdown>
		</div>
	);
};
