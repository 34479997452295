import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../Icon';
import classNames from 'classnames';

const baseClasses = 'cursor-pointer select-none outline-none';

const StudyItem = ({
	date,
	description,
	numInstances,
	numSeries,
	modalities,
	trackedSeries,
	patientName,
	patientBirthDate,
	isActive,
	onClick,
	onSubPanel
}) => {
	return (
		<div
			className={classnames('bg-primary-main', baseClasses)}
			onClick={onClick}
			onKeyDown={onClick}
			role="button"
			tabIndex={0}
		>
			<div className={classNames('flex flex-1 border border-common-dark', onSubPanel ? 'flex-row' : 'flex-col')}>
				{/* <div className="flex flex-row items-center justify-between border-b border-primary-main">
					<div className="flex flex-row items-center text-xs text-common-light">
						<Icon
							name="group-layers"
							className="mx-2 w-4 text-common-light"
						/>
						{numSeries} Series
					</div>
				</div> */}
				<div className="flex flex-1 flex-col pt-1">
					<div className="flex flex-row items-center justify-center truncate text-xs text-common-light">
						{patientName ? patientName : '<PatientName>'}
					</div>
					<div className="flex flex-row items-center justify-center truncate text-xs text-common-light">
						{patientBirthDate ? patientBirthDate : '<patientBirthDate>'}
					</div>
				</div>
				{!onSubPanel && <div className="mx-3 border-t border-common-dark"></div>}
				<div className="flex flex-1 flex-col pt-1">
					<div className="flex flex-row items-center justify-center truncate">
						<div className="flex flex-row text-xs text-common-light">{date}</div>
					</div>
					<div className="flex flex-row items-center justify-center truncate">
						{/* <div className="pr-5 text-xl text-blue-300">{modalities}</div> */}
						<div className="truncate text-xs text-common-light">{description}</div>
					</div>
				</div>
			</div>
			{!!trackedSeries && (
				<div className="flex-2 flex">
					<div
						className={classnames(
							'mt-2 flex flex-row bg-secondary-main py-1 pl-2 pr-4 text-xs text-common-light ',
							isActive ? 'flex-1 justify-center border-t border-secondary-light' : 'mx-4 mb-4 rounded-sm'
						)}
					>
						<Icon
							name="tracked"
							className="mr-2 w-4 text-primary-light"
						/>
						{trackedSeries} Tracked Series
					</div>
				</div>
			)}
		</div>
	);
};

StudyItem.propTypes = {
	date: PropTypes.string.isRequired,
	description: PropTypes.string,
	patientName: PropTypes.string,
	patientBirthDate: PropTypes.string,
	modalities: PropTypes.string.isRequired,
	numInstances: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	numSeries: PropTypes.number,
	trackedSeries: PropTypes.number,
	isActive: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	onSubPanel: PropTypes.bool
};

export default StudyItem;
