import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const stickyClasses = 'sticky top-0';
const notStickyClasses = 'relative';

const NavBar = ({ className, children, isSticky }) => {
	return (
		<div
			className={classnames(
				'z-20 flex flex-row items-center border-black bg-primary-main',
				isSticky && stickyClasses,
				!isSticky && notStickyClasses,
				className
			)}
		>
			{children}
		</div>
	);
};

NavBar.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	isSticky: PropTypes.bool
};

export default NavBar;
