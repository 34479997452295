import { useEffect, useMemo, useState } from 'react';

import { ServicesManager } from '@core';
import { DefaultViewConfig, ViewSetting } from '@extensions/default/ViewSetting/viewConfig';
import { ModeID } from 'types';

export const useGetSubPanelDisplayState = ({
	servicesManager,
	modeID,
	selectedButtonSection
}: {
	servicesManager: ServicesManager;
	modeID: ModeID;
	selectedButtonSection: string;
}) => {
	const { panelService } = servicesManager.services;
	const [currentViewSetting, setCurrentViewSetting] = useState<ViewSetting>(DefaultViewConfig[modeID]);
	const subPanelDisplayState = useMemo(() => currentViewSetting.toolSidebar.subPanel, [currentViewSetting.toolSidebar.subPanel]);

	useEffect(() => {
		const setDefaultViewSetting = async () => {
			const localViewSetting = await panelService.getViewSetting(ModeID[modeID]);
			localViewSetting ? setCurrentViewSetting(localViewSetting) : setCurrentViewSetting(DefaultViewConfig[modeID]);
		};
		setDefaultViewSetting();
	}, [modeID, panelService]);

	useEffect(() => {
		const { unsubscribe } = panelService.subscribe(panelService.EVENTS.PANEL_POSITION_CHANGED, ({ viewSetting, modeId }) => {
			if (selectedButtonSection === modeId) {
				setCurrentViewSetting(viewSetting);
			}
		});

		return () => {
			unsubscribe();
		};
	}, [panelService, selectedButtonSection]);

	return subPanelDisplayState;
};
