import { CommandsManager, ServicesManager } from '@core';
import { WindowLevelMenuItem } from '@ui';
import classNames from 'classnames';
import React from 'react';
import { ListCustomPreset } from './ListCustomPreset';

/**
 * Nút User Define Preset + Danh sách Preset đã tạo
 */
export const CustomPresets = ({
	commandsManager,
	servicesManager
}: {
	commandsManager: CommandsManager;
	servicesManager: ServicesManager;
}) => {
	return (
		<>
			<div
				className={classNames('cursor-pointer bg-primary-main text-common-bright')}
				onClick={() => {
					commandsManager.runCommand('openPopupDefinePreset');
				}}
			>
				<WindowLevelMenuItem title="User Define" />
			</div>
			<ListCustomPreset
				commandsManager={commandsManager}
				serviceManager={servicesManager}
			/>
		</>
	);
};
