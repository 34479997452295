import { useAppConfig } from '@state';
import useSearchParams from './useSearchParams';
import { utils } from '@core';
import { useCallback } from 'react';
import { RESOURCES } from 'types';
import { CreateShareSessionDTO, GetShareLinkDTO } from 'types/Share';

export const useShareLink = () => {
	const config = useAppConfig();
	const baseURL = config.dataSources[0].configuration.apiRoot;
	const searchParams = useSearchParams();
	const sessionID = utils.splitComma(searchParams.getAll(RESOURCES.SESSION))[0];
	/**
	 * Lấy url share link và password
	 */
	const getShareLink = useCallback(
		async (body: CreateShareSessionDTO): Promise<GetShareLinkDTO> => {
			let shareLink: GetShareLinkDTO;
			if (sessionID) {
				try {
					const response = await fetch(`${baseURL}/${RESOURCES.SESSION}/${sessionID}/${RESOURCES.SHARE}`, {
						method: 'POST',
						headers: {
							'content-type': 'application/json;charset=UTF-8'
						},
						body: JSON.stringify(body)
					});
					if (response.ok) {
						shareLink = await response.json();
					} else {
						console.error('Promise resolved but HTTP status failed');
					}
				} catch (error) {
					console.log(error);
				}
			}

			return shareLink;
		},
		[baseURL, sessionID]
	);

	return { getShareLink };
};
