import React, { FC, useState } from 'react';
import { Button, ButtonEnums, CheckBox, Select, Typography } from '@ui';
import { CreateShareSessionDTO } from 'types/Share';

/**
 * Hàm chuyển đổi option từ ngày giờ sang giây có dạng {value, label}
 */
const convertOptionsArray = (optionsArray) => {
	const convertedArray = [];

	for (let i = 0; i < optionsArray.length; i++) {
		const [timeValue, timeUnit] = optionsArray[i].split(' ');
		const intValue = parseInt(timeValue, 10);
		let value;

		if (timeUnit === 'hour' || timeUnit === 'hours') {
			value = intValue * 60;
		} else if (timeUnit === 'day' || timeUnit === 'days') {
			value = intValue * 1440;
		} else {
			throw new Error("Invalid format passed. Please use 'hour', 'hours', 'day' or 'days'.");
		}

		convertedArray.push({ value: value.toString(), label: optionsArray[i] });
	}

	return convertedArray;
};

const optionsArray = ['1 hour', '6 hours', '12 hours', '1 day', '3 days', '7 days', '30 days', '90 days'];
const options = convertOptionsArray(optionsArray);

type ShareLinkModalProps = {
	onSubmit: (form: CreateShareSessionDTO) => void;
};

const defaultValue = {
	anonymized: false,
	expireIn: 60,
	requiresShareKey: false,
	usePublicURL: false
};
/**
 * Content của popup Share link
 */
const ShareLinkModal: FC<ShareLinkModalProps> = ({ onSubmit }) => {
	const [expireIn, setExpireIn] = useState({
		value: '60',
		label: '1 hour'
	});
	const [formData, setFormData] = useState<CreateShareSessionDTO>(defaultValue);

	const params = new URLSearchParams(new URL(window.location.href).search);
	const sessionID = params.get('session');

	return sessionID ? (
		<div className="mx-auto h-44 w-[330px] space-y-4">
			<div className="flex items-center space-x-2">
				<Typography
					component="div"
					variant="body"
					className="text-sm"
				>
					Share Duration
				</Typography>
				<Select
					id={'a'}
					options={options}
					placeholder="1 hour"
					value={expireIn}
					onChange={(value) => {
						setExpireIn(value);
						setFormData((state) => ({ ...state, expireIn: Number(value.value) }));
					}}
					isClearable={false}
					className="custom-scrollbar border-white"
					isWhiteColor
				/>
			</div>
			<div className="space-y-2">
				<CheckBox
					checked={false}
					label="Hide Info"
					onChange={(value) => {
						setFormData((state) => ({ ...state, anonymized: value }));
					}}
				/>
				<CheckBox
					checked={false}
					label="Share inside hospital"
					onChange={(value) => {
						setFormData((state) => ({ ...state, usePublicURL: value }));
					}}
				/>
				<CheckBox
					checked={false}
					label="Safe sharing (Need enter the password to review)"
					onChange={(value) => {
						setFormData((state) => ({ ...state, requiresShareKey: value }));
					}}
				/>
			</div>
			<div className="flex justify-end pt-4">
				<Button
					type={ButtonEnums.type.primary}
					className="w-[60px]"
					onClick={() => {
						onSubmit(formData);
					}}
				>
					Share
				</Button>
			</div>
		</div>
	) : (
		<>No study to share</>
	);
};

export default ShareLinkModal;
