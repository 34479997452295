import classnames from 'classnames';
import React, { FC, PropsWithChildren, useRef } from 'react';

const baseClasses = 'text-center items-center justify-center transition duration-300 ease-in-out outline-none font-bold focus:outline-none';

const disabledClasses = {
	true: 'cursor-not-allowed',
	false: ''
};

const variantClasses = {
	text: {
		default: 'text-white hover:bg-primary-light hover:text-white focus:!bg-primary-light focus:text-white',
		primary: 'text-primary-main hover:bg-primary-main hover:text-white  focus:bg-primary-main focus:text-white',
		secondary: 'text-secondary-light hover:bg-secondary-light hover:text-white  focus:bg-secondary-light focus:text-white',
		white: 'text-white hover:bg-white hover:text-black  focus:bg-white focus:text-black',
		black: 'text-black hover:bg-black hover:text-white focus:bg-black focus:text-white'
	},
	outlined: {
		default: 'border border-primary-light text-white hover:opacity-80 active:opacity-100 focus:opacity-80',
		primary: 'border border-primary-main text-primary-main hover:opacity-80 active:opacity-100 focus:opacity-80',
		secondary: 'border border-secondary-light text-secondary-light hover:opacity-80 active:opacity-100 focus:opacity-80',
		white: 'border border-white text-white hover:opacity-80 active:opacity-100 focus:opacity-80',
		black: 'border border-primary-main text-white hover:bg-primary-main focus:bg-primary-main hover:border-black focus:border-black'
	},
	contained: {
		default: 'text-black',
		primary: 'text-white',
		secondary: 'text-white',
		white: 'text-black',
		black: 'text-white'
	}
};

const backgroundClasses = {
	text: {
		default: '',
		primary: '',
		secondary: '',
		white: '',
		black: ''
	},
	outlined: {
		default: 'bg-transparent',
		primary: 'bg-transparent',
		secondary: 'bg-transparent',
		white: 'bg-transparent',
		black: 'bg-black'
	},
	contained: {
		default: 'bg-primary-active',
		primary: 'bg-primary-main',
		secondary: 'bg-secondary-active',
		white: 'bg-white',
		black: 'bg-black'
	}
};

const sizeClasses = {
	small: 'py-2 px-2 text-base',
	medium: 'py-3 px-3 text-lg',
	large: 'py-4 px-4 text-xl',
	initial: '',
	toolbar: 'text-lg p-3',
	quickToolbar: 'text-lg p-2  rounded-[2px]'
};

const iconSizeClasses = {
	small: 'w-4 h-4',
	medium: 'w-5 h-5',
	large: 'w-6 h-6',
	toolbar: 'w-6 h-6',
	quickToolbar: 'w-6 h-6'
};

const fullWidthClasses = {
	true: 'flex w-full',
	false: 'inline-flex'
};

type IconButtonProps = {
	size?: 'small' | 'medium' | 'large' | 'initial' | 'toolbar' | 'quickToolbar';
	variant?: 'text' | 'outlined' | 'contained';
	color?: 'default' | 'primary' | 'secondary' | 'white' | 'black' | 'inherit';
	fullWidth?: boolean;
	disabled?: boolean;
	type?: 'button' | 'submit' | 'reset';
	id: string;
	className?: string;
	onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
	bgColor?: string;
	name?: string;
};

const IconButton: FC<PropsWithChildren<IconButtonProps>> = ({
	variant = 'contained',
	color = 'default',
	size = 'medium',
	disabled = false,
	type = 'button',
	fullWidth = false,
	onClick = (e) => {},
	className = '',
	id,
	bgColor = undefined,
	children,
	...rest
}) => {
	const buttonElement = useRef(null);

	const handleOnClick = (e) => {
		buttonElement.current.blur();
		onClick(e);
	};
	const bgColorToUse = bgColor ? bgColor : backgroundClasses[variant][color];

	return (
		<button
			className={classnames(
				baseClasses,
				variantClasses[variant][color],
				sizeClasses[size],
				fullWidthClasses[fullWidth.toString()],
				disabledClasses[disabled.toString()],
				bgColorToUse,
				className
			)}
			ref={buttonElement}
			onClick={handleOnClick}
			type={type}
			data-cy={rest['data-cy'] ?? id}
			data-tool={rest['data-tool']}
		>
			{React.cloneElement(children as React.ReactElement<any>, {
				className: classnames(iconSizeClasses[size], 'fill-current')
			})}
		</button>
	);
};

export default IconButton;
