import classNames from 'classnames';
import React from 'react';
import ReactModal from 'react-modal';

import { useModal } from '../../contextProviders';
import Icon from '../Icon';
import IconButton from '../IconButton';
import Typography from '../Typography';

import './Modal.css';

if (typeof document !== 'undefined') {
	ReactModal.setAppElement(document.getElementById('root'));
}

interface ModalProps {
	className?: string;
	closeButton?: boolean;
	shouldCloseOnEsc?: boolean;
	isOpen: boolean;
	title?: string;
	onClose?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	children: React.ReactNode;
	shouldCloseOnOverlayClick?: boolean;
	customClassNameContent?: string;
	paddingContent?: string;
}

const Modal = ({
	className,
	closeButton = false,
	shouldCloseOnEsc = false,
	isOpen = false,
	title,
	onClose = (...args) => undefined,
	children,
	shouldCloseOnOverlayClick = true,
	customClassNameContent,
	paddingContent
}: ModalProps) => {
	const { hide } = useModal();

	const handleClose = () => {
		hide();
	};

	const renderHeader = () => {
		return (
			title && (
				<header className="flex h-8 items-center bg-primary-light">
					<Typography
						variant="body"
						color="primaryLight"
						className="flex grow px-2 text-sm"
						data-cy="modal-header"
					>
						{title}
					</Typography>
					{closeButton && (
						<IconButton
							id="model-close"
							onClick={onClose}
							name="close"
							variant="text"
							className="cursor-pointer text-common-light hover:!bg-primary-active"
							size="small"
						>
							<Icon
								name="close"
								className="cursor-pointer text-common-light"
							/>
						</IconButton>
					)}
				</header>
			)
		);
	};

	return (
		<ReactModal
			className={classNames(className, 'relative max-h-full text-white outline-none')}
			overlayClassName="fixed items-center top-0 left-0 right-0 bottom-0 z-50 bg-overlay flex items-start justify-center"
			shouldCloseOnEsc={shouldCloseOnEsc}
			onRequestClose={handleClose}
			isOpen={isOpen}
			title={title}
			shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
		>
			{renderHeader()}
			<section
				className={`modal-content overflow-y-auto bg-primary-main ${customClassNameContent} ${paddingContent ? paddingContent : 'px-4 py-4'}`}
			>
				{children}
			</section>
		</ReactModal>
	);
};

export default Modal;
