import { CommandsManager, ServicesManager } from '@core';
import React, { useMemo } from 'react';
import { ListMenu, WindowLevelMenuItem } from '@ui';
import { useGetPresetData } from '@hooks';
import { createWwwcPreset } from '@utils';

/**
 * Hiển thị danh sách preset user đã tạo
 */
export const ListCustomPreset = ({
	commandsManager,
	serviceManager
}: {
	commandsManager: CommandsManager;
	serviceManager: ServicesManager;
}) => {
	const presetData = useGetPresetData(serviceManager, true);

	const items = useMemo(() => {
		const listItems = presetData.map((item, index) => {
			return createWwwcPreset({
				preset: {
					description: item.name,
					window: item.ww.toString(),
					level: item.wl.toString()
				},
				type: 'NORMAL',
				isVisibleShortcutKey: false
			});
		});
		return listItems;
	}, [presetData]);

	const onClick = ({ item, selectedIndex: string }) => {
		commandsManager.run(item.commands);
	};

	return (
		<ListMenu
			items={items}
			renderer={WindowLevelMenuItem}
			className={'rounded-none border-none !py-0'}
			onClick={onClick}
			activeOptions={false}
		/>
	);
};
