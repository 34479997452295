import { ModeID } from 'types';

export enum SidebarPosition {
	HIDDEN = 'Hidden',
	LEFT = 'Left',
	RIGHT = 'Right'
}

/**
 * mode MPR
 * Các panel có thể hiển trong side tool panel
 */
export enum SubPanelMpr {
	MPR_LAYOUT = 'Mpr layout',
	MPR_TOOL = 'Mpr tools',
	WINDOW_LEVEL = 'Window level',
	SERIES_THUMBNAILS = 'Series thumbnails'
}
/**
 * mode 2D
 * Các panel có thể hiển trong side tool panel
 */
export enum SubPanel2D {
	SERIES_LAYOUT = 'Series layout',
	IMAGE_TOOL = 'Image tools',
	WINDOW_LEVEL = 'Window level',
	SERIES_THUMBNAILS = 'Series thumbnails'
}

/**
 * mode MPR
 * Các panel có thể hiển trong side tool panel
 */
export enum SubPanel3D {
	LAYOUT = 'Layout',
	RENDERING_TOOLS = 'Rendering tools',
	ADVANCED_TOOLS = 'Advanced tools',
	SERIES_THUMBNAILS = 'Series thumbnails'
}

export type SidebarSubPanel = SubPanelMpr | SubPanel2D | SubPanel3D;

/**
 * 2 Panel chính trong app
 */
export type SidePanelType = 'toolSidebar' | 'seriesSidebar';

export type SidePanelConfig = {
	position: `${SidebarPosition}`;
	subPanel?: Record<SidebarSubPanel, boolean>;
};

export type ViewSetting = Record<SidePanelType, SidePanelConfig>;

export const DefaultViewConfig: Record<ModeID, ViewSetting> = {
	default: {
		seriesSidebar: { position: 'Right' },
		toolSidebar: {
			position: 'Left',
			subPanel: {
				'Image tools': true,
				'Mpr layout': false,
				'Series layout': true,
				'Mpr tools': false,
				'Window level': true,
				'Series thumbnails': false,
				'Advanced tools': false,
				'Rendering tools': false,
				Layout: false
			}
		}
	},
	mpr: {
		seriesSidebar: { position: 'Right' },
		toolSidebar: {
			position: 'Left',
			subPanel: {
				'Image tools': false,
				'Mpr layout': true,
				'Mpr tools': true,
				'Series layout': false,
				'Series thumbnails': false,
				'Window level': true,
				'Advanced tools': false,
				'Rendering tools': false,
				Layout: false
			}
		}
	},
	remoteRender3D: {
		seriesSidebar: { position: 'Right' },
		toolSidebar: {
			position: 'Left',
			subPanel: {
				'Image tools': false,
				'Mpr layout': false,
				'Series layout': false,
				'Mpr tools': false,
				'Window level': false,
				'Series thumbnails': false,
				'Advanced tools': true,
				'Rendering tools': true,
				Layout: true
			}
		}
	},
	'pt/ct': {
		seriesSidebar: { position: 'Hidden' },
		toolSidebar: {
			position: 'Hidden',
			subPanel: {
				'Image tools': false,
				'Mpr layout': false,
				'Mpr tools': true,
				'Series layout': false,
				'Series thumbnails': false,
				'Window level': true,
				'Advanced tools': false,
				'Rendering tools': false,
				Layout: false
			}
		}
	},
	remoteRenderEndo: {
		seriesSidebar: { position: 'Hidden' },
		toolSidebar: {
			position: 'Left',
			subPanel: {
				'Image tools': false,
				'Mpr layout': true,
				'Series layout': false,
				'Mpr tools': false,
				'Window level': false,
				'Series thumbnails': true,
				'Advanced tools': true,
				'Rendering tools': true,
				Layout: true
			}
		}
	}
};
