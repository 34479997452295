import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { useOnClickOutside } from '@hooks';
import Icon from '../Icon';

const MprPlanes = [
	{ name: 'Axial', icon: 'tool-axial', shortcutKey: 'Shift+A' },
	{ name: 'Coronal', icon: 'tool-coronal', shortcutKey: 'Shift+C' },
	{ name: 'Sagittal', icon: 'tool-sagittal', shortcutKey: 'Shift+S' }
];

const MprPlanesSelector = ({ isActive, onClickOutside, onSelection }) => {
	const ref = useRef(null);
	useOnClickOutside(ref, onClickOutside);
	return (
		<div
			ref={ref}
			className="z-10 flex flex-col rounded-md border border-common-dark bg-secondary-dark pb-1 pt-1"
		>
			{MprPlanes.map((item) => (
				<div
					key={item.name}
					className={classNames(
						'flex h-7 w-full flex-row items-center px-2 text-sm',
						'whitespace-pre bg-primary-dark text-sm font-normal',
						isActive && 'bg-primary-light',
						isActive ? 'text-primary-light' : 'text-common-light hover:bg-primary-light'
					)}
					onClick={onSelection}
					data-orientation={item.name.toLowerCase()}
				>
					<span className="mr-4 flex-initial">
						<Icon
							name={item.icon}
							className="h-6 w-6 text-common-light"
						/>
					</span>

					<span className="w-full flex-1">{item.name}</span>
					<span className="ml-5">{`(${item.shortcutKey})`}</span>
				</div>
			))}
		</div>
	);
};

MprPlanesSelector.propTypes = {
	isActive: PropTypes.bool,
	onClickOutside: PropTypes.func,
	onSelection: PropTypes.func
};

export default MprPlanesSelector;
