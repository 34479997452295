import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useDrag } from 'react-dnd';

import { Enums } from '@cornerstonejs/core';
import { StringNumber } from '../../types';
import ProgressLoadingBar from '../ProgressLoadingBar';

const getOrientationShortLabel = (orientation: string): string => {
	switch (orientation) {
		case Enums.OrientationAxis.AXIAL:
			return 'A';
		case Enums.OrientationAxis.SAGITTAL:
			return 'S';
		case Enums.OrientationAxis.CORONAL:
			return 'C';
		case Enums.OrientationAxis.ACQUISITION:
			return 'Q';
		default:
			return '';
	}
};

/**
 * Display a thumbnail for a display set.
 */
const Thumbnail = ({
	displaySetInstanceUID,
	className,
	imageSrc,
	// imageAltText,
	description,
	seriesNumber,
	numInstances,
	orientation,
	// messages,
	dragData = {},
	isActive,
	onClick,
	onDoubleClick,
	loadingProgress
}): React.ReactElement => {
	// TODO: We should wrap our thumbnail to create a "DraggableThumbnail", as
	// this will still allow for "drag", even if there is no drop target for the
	// specified item.
	const [_collectedProps, drag, _dragPreview] = useDrag({
		type: 'displayset',
		item: {
			...dragData
		},
		canDrag: function (_monitor) {
			return Object.keys(dragData).length !== 0;
		}
	});

	const style = imageSrc
		? {
				height: `120px`,
				width: `100px`,
				backgroundImage: `url("${imageSrc}")`,
				backgroundRepeat: `no-repeat`,
				backgroundSize: 'contain',
				backgroundPosition: `center center`
			}
		: {
				height: `120px`,
				width: `100px`
			};
	return (
		<div
			className={classnames(
				className,
				'group flex w-full flex-1 cursor-pointer select-none flex-col outline-none',
				isActive ? 'border border-primary-light text-primary-light' : 'border border-common-dark text-common-light'
			)}
			id={`thumbnail-${displaySetInstanceUID}`}
			data-cy={`study-browser-thumbnail`}
			onClick={onClick}
			onDoubleClick={onDoubleClick}
			role="button"
			tabIndex={0}
			style={style}
		>
			<div
				ref={drag}
				className="flex h-full w-full flex-1 flex-col justify-between"
			>
				<div className="max-w-24 truncate text-center text-xs">
					{`${orientation ? `(${getOrientationShortLabel(orientation)}) ` : ''}${description}`}
				</div>
				{/* <div
					className={classnames('flex flex-1 items-center justify-center overflow-hidden rounded-md bg-black text-xs ')}
					style={{
						margin: isActive ? '0' : '1px',
						width: `100%`
					}}
				>
					{imageSrc ? (
						<img
							src={imageSrc}
							alt={imageAltText}
							className="object-none"
							crossOrigin="anonymous"
						/>
					) : (
						<div>{imageAltText}</div>
					)}
				</div> */}
				<div className="flex flex-col">
					<ProgressLoadingBar
						progress={loadingProgress * 100}
						className="mx-1 h-[2px] w-auto rounded-none bg-transparent"
					/>
					<div className="flex flex-row items-center text-xs">
						<div className="ml-1 flex flex-1 flex-row items-center">
							{/* <Icon
							name={countIcon || 'group-layers'}
							className="mr-1 w-3"
						/> */}
							{`${numInstances}`}
						</div>
						<div className="mr-1">
							<span>{'Ser: '}</span>
							{seriesNumber}
						</div>
						{/* <DisplaySetMessageListTooltip
						messages={messages}
						id={`display-set-tooltip-${displaySetInstanceUID}`}
					/> */}
					</div>
				</div>
			</div>
		</div>
	);
};

Thumbnail.propTypes = {
	displaySetInstanceUID: PropTypes.string.isRequired,
	className: PropTypes.string,
	imageSrc: PropTypes.string,
	/**
	 * Data the thumbnail should expose to a receiving drop target. Use a matching
	 * `dragData.type` to identify which targets can receive this draggable item.
	 * If this is not set, drag-n-drop will be disabled for this thumbnail.
	 *
	 * Ref: https://react-dnd.github.io/react-dnd/docs/api/use-drag#specification-object-members
	 */
	dragData: PropTypes.shape({
		/** Must match the "type" a dropTarget expects */
		type: PropTypes.string.isRequired
	}),
	imageAltText: PropTypes.string,
	description: PropTypes.string.isRequired,
	seriesNumber: StringNumber.isRequired,
	numInstances: PropTypes.number.isRequired,
	messages: PropTypes.object,
	isActive: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
	onDoubleClick: PropTypes.func.isRequired,
	orientation: PropTypes.string,
	viewportIdentificator: PropTypes.arrayOf(PropTypes.string),
	loadingProgress: PropTypes.number
};

export default Thumbnail;
