import { sopClassDictionary } from './sopClassDictionary';

const imagesTypes = [
	sopClassDictionary.ComputedRadiographyImageStorage,
	sopClassDictionary.DigitalXRayImageStorageForPresentation,
	sopClassDictionary.DigitalXRayImageStorageForProcessing,
	sopClassDictionary.DigitalMammographyXRayImageStorageForPresentation,
	sopClassDictionary.DigitalMammographyXRayImageStorageForProcessing,
	sopClassDictionary.DigitalIntraOralXRayImageStorageForPresentation,
	sopClassDictionary.DigitalIntraOralXRayImageStorageForProcessing,
	sopClassDictionary.CTImageStorage,
	sopClassDictionary.EnhancedCTImageStorage,
	sopClassDictionary.LegacyConvertedEnhancedCTImageStorage,
	sopClassDictionary.UltrasoundMultiframeImageStorage,
	sopClassDictionary.EnhancedUSVolumeStorage,
	sopClassDictionary.MRImageStorage,
	sopClassDictionary.EnhancedMRImageStorage,
	sopClassDictionary.EnhancedMRColorImageStorage,
	sopClassDictionary.LegacyConvertedEnhancedMRImageStorage,
	sopClassDictionary.UltrasoundImageStorage,
	sopClassDictionary.SecondaryCaptureImageStorage,
	sopClassDictionary.MultiframeSingleBitSecondaryCaptureImageStorage,
	sopClassDictionary.MultiframeGrayscaleByteSecondaryCaptureImageStorage,
	sopClassDictionary.MultiframeGrayscaleWordSecondaryCaptureImageStorage,
	sopClassDictionary.MultiframeTrueColorSecondaryCaptureImageStorage,
	sopClassDictionary.XRayAngiographicImageStorage,
	sopClassDictionary.EnhancedXAImageStorage,
	sopClassDictionary.XRayRadiofluoroscopicImageStorage,
	sopClassDictionary.EnhancedXRFImageStorage,
	sopClassDictionary.XRay3DAngiographicImageStorage,
	sopClassDictionary.XRay3DCraniofacialImageStorage,
	sopClassDictionary.BreastTomosynthesisImageStorage,
	sopClassDictionary.BreastProjectionXRayImageStorageForPresentation,
	sopClassDictionary.BreastProjectionXRayImageStorageForProcessing,
	sopClassDictionary.IntravascularOpticalCoherenceTomographyImageStorageForPresentation,
	sopClassDictionary.IntravascularOpticalCoherenceTomographyImageStorageForProcessing,
	sopClassDictionary.NuclearMedicineImageStorage,
	sopClassDictionary.VLEndoscopicImageStorage,
	sopClassDictionary.VideoEndoscopicImageStorage,
	sopClassDictionary.VLMicroscopicImageStorage,
	sopClassDictionary.VideoMicroscopicImageStorage,
	sopClassDictionary.VLSlideCoordinatesMicroscopicImageStorage,
	sopClassDictionary.VLPhotographicImageStorage,
	sopClassDictionary.VideoPhotographicImageStorage,
	sopClassDictionary.OphthalmicPhotography8BitImageStorage,
	sopClassDictionary.OphthalmicPhotography16BitImageStorage,
	sopClassDictionary.OphthalmicTomographyImageStorage,
	sopClassDictionary.VLWholeSlideMicroscopyImageStorage,
	sopClassDictionary.PositronEmissionTomographyImageStorage,
	sopClassDictionary.EnhancedPETImageStorage,
	sopClassDictionary.LegacyConvertedEnhancedPETImageStorage,
	sopClassDictionary.RTImageStorage
];

/**
 * Checks whether dicom files with specified SOP Class UID have image data
 * @param {string} SOPClassUID - SOP Class UID to be checked
 * @returns {boolean} - true if it has image data
 */
export const isImage = (SOPClassUID: string): boolean => {
	if (!SOPClassUID) {
		return false;
	}
	return imagesTypes.indexOf(SOPClassUID) !== -1;
};
