const getWindowLevelPresets = (modality: string) => {
	const isXQModality = ['DX', 'DR', 'CR', 'MG'].includes(modality);
	if (isXQModality) {
		return WINDOW_LEVEL_PRESETS_XQ;
	}
	if (modality === 'CT') {
		return WINDOW_LEVEL_PRESETS_CT;
	}
	if (modality === 'MR') {
		return WINDOW_LEVEL_PRESETS_MR;
	}
	return undefined;
};

const WINDOW_LEVEL_PRESETS_XQ = {
	1: {
		description: 'Preset 1',
		window: '2560',
		level: '1280'
	},
	2: {
		description: 'Preset 2',
		window: '5120',
		level: '2560'
	},
	3: {
		description: 'Preset 3',
		window: '10000',
		level: '5000'
	},
	4: {
		description: 'Preset 4',
		window: '30000',
		level: '15000'
	}
};

const WINDOW_LEVEL_PRESETS_MR = {
	1: {
		description: 'Preset 1',
		window: '80',
		level: '40'
	},
	2: {
		description: 'Preset 2',
		window: '160',
		level: '80'
	},
	3: {
		description: 'Preset 3',
		window: '256',
		level: '128'
	},
	4: {
		description: 'Preset 4',
		window: '320',
		level: '160'
	},
	5: {
		description: 'Preset 4',
		window: '640',
		level: '320'
	}
};

const WINDOW_LEVEL_PRESETS_CT = {
	1: {
		description: 'Brain',
		window: '90',
		level: '35'
	},
	2: {
		description: 'Brain Bone',
		window: '1600',
		level: '450'
	},
	3: {
		description: 'Spine',
		window: '4000',
		level: '700'
	},
	4: {
		description: 'Lung',
		window: '1500',
		level: '-400'
	},
	5: {
		description: 'Mediatinum',
		window: '350',
		level: '40'
	},
	6: {
		description: 'Abdomen',
		window: '1500',
		level: '-700'
	},
	7: {
		description: 'Liver',
		window: '400',
		level: '40'
	},
	8: {
		description: 'Bone',
		window: '1600',
		level: '550'
	},
	9: {
		description: 'Vessel',
		window: '500',
		level: '40'
	}
};

export default getWindowLevelPresets;
