import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import ProgressLoadingBar from '../ProgressLoadingBar';

/**
 *  A React component that renders a loading indicator.
 * if progress is not provided, it will render an infinite loading indicator
 * if progress is provided, it will render a progress bar
 * Optionally a textBlock can be provided to display a message
 */
const LoadingIndicatorProgress = ({ className, textBlock, progress }) => {
	return (
		<div className={classNames('absolute left-0 top-0 z-50 flex flex-col items-center justify-center space-y-1', className)}>
			<div className="text-lg text-primary-light">Loading...</div>
			<div className="w-48">
				<ProgressLoadingBar progress={progress} />
			</div>
			<div className="text-lg text-primary-light">{textBlock}</div>
		</div>
	);
};

LoadingIndicatorProgress.propTypes = {
	className: PropTypes.string,
	textBlock: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	progress: PropTypes.number
};

export default LoadingIndicatorProgress;
