import React, { useRef } from 'react';
import { UserDefinedPresetForm } from './UserDefinedPresetForm';
import PresetTable from './PresetTable';
import { ServicesManager } from '@core';
import { SavePresetButton } from './SavePresetButton';
import { useForm } from 'react-hook-form';
import { Preset } from 'types';

/**
 * POPUP Thêm sửa xóa preset
 */
export const UserDefinedPresetWrapper = ({ onClose, servicesManager }: { onClose: () => void; servicesManager: ServicesManager }) => {
	const refSubmitButton = useRef<HTMLInputElement>(null);
	const formControls = useForm<Preset>({
		defaultValues: {
			modality: 'ALL',
			name: 'Custom 1',
			ww: 5000,
			wl: 5000
		}
	});

	const triggerSubmit = () => {
		refSubmitButton?.current?.click();
	};

	return (
		<div className="flex h-64 w-[460px] flex-col gap-2 overflow-hidden p-3">
			<UserDefinedPresetForm
				refSubmitButton={refSubmitButton}
				servicesManager={servicesManager}
				formControls={formControls}
			/>
			<PresetTable
				serviceManager={servicesManager}
				formControls={formControls}
			/>
			<SavePresetButton onSave={() => triggerSubmit()} />
		</div>
	);
};
