import React, { useState } from 'react';

import { useUserAuthentication } from '@core';
import { Button, ButtonEnums, Input, Typography } from '@ui';

/**
 * Form nhập session-key
 */
export const SessionForm = () => {
	const params = new URLSearchParams(new URL(window.location.href).search);
	const sessionID = params.get('session');

	const { func } = useUserAuthentication();
	const [sessionKey, setSessionKey] = useState<string>();
	const { setSession } = func;
	const onSubmitSessionKey = () => {
		setSession({ sessionKey });
	};

	return (
		<>
			<div className="flex flex-col space-y-4 pb-2 pt-2">
				<Typography
					className="truncate text-sm"
					color="primaryLight"
				>
					Session ID &nbsp; {sessionID}
				</Typography>
				<Input
					id="input-file-name"
					data-cy="file-name"
					onChange={(evt) => {
						setSessionKey(evt.currentTarget.value);
					}}
					label={'Password'}
					labelClassName="text-sm text-common-light"
					type="password"
					isFlexRow
					smallInput
					transparent
				/>
			</div>
			<div className="flex justify-end pt-4">
				<Button
					onClick={() => {
						onSubmitSessionKey();
					}}
					type={ButtonEnums.type.primary}
					className="w-[60px]"
				>
					Confirm
				</Button>
			</div>
		</>
	);
};
