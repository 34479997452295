export default function resolveObjectPath(root: object, path: string, defaultValue: any) {
	if (root !== null && typeof root === 'object' && typeof path === 'string') {
		const separator = path.indexOf('.');
		if (separator >= 0) {
			return resolveObjectPath(root[path.slice(0, separator)], path.slice(separator + 1, path.length), defaultValue);
		}
		const value = root[path];
		return value === undefined && defaultValue !== undefined ? defaultValue : value;
	}
}
