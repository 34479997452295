const extensions: Array<string> = [];
const modes: Array<string> = [];

// Not required any longer
window.extensions = extensions;
window.modes = modes;

extensions.push('@extensions/default');
extensions.push('@extensions/cornerstone');
extensions.push('@extensions/remote-render');
extensions.push('@extensions/tmtv');
modes.push('@modes/default');
modes.push('@modes/mobile');
modes.push('@modes/tmtv');

// modes.push('@modes/segmentation');
// modes.push('@modes/microscopy');

// Add a dynamic runtime loader
async function loadModule(module: string) {
	if (typeof module !== 'string') {
		return module;
	}
	// console.log(`load extension ${module} - start`);
	if (module === '@extensions/default') {
		const imported = await import('@extensions/default');
		// console.log(`load extension default - done`, imported.default);
		return imported.default;
	}
	if (module === '@extensions/cornerstone') {
		const imported = await import('@extensions/cornerstone');
		// console.log(`load extension cornerstone - done`, imported.default);
		return imported.default;
	}
	if (module === '@extensions/measurement-tracking') {
		const imported = await import('@extensions/measurement-tracking');
		return imported.default;
	}
	if (module === '@extensions/cornerstone-dicom-sr') {
		const imported = await import('@extensions/cornerstone-dicom-sr');
		return imported.default;
	}
	if (module === '@extensions/cornerstone-dicom-seg') {
		const imported = await import('@extensions/cornerstone-dicom-seg');
		return imported.default;
	}
	if (module === '@extensions/dicom-microscopy') {
		const imported = await import('@extensions/dicom-microscopy');
		return imported.default;
	}
	if (module === '@extensions/dicom-pdf') {
		const imported = await import('@extensions/dicom-pdf');
		return imported.default;
	}
	if (module === '@extensions/dicom-video') {
		const imported = await import('@extensions/dicom-video');
		return imported.default;
	}
	if (module === '@extensions/tmtv') {
		const imported = await import('@extensions/tmtv');
		return imported.default;
	}
	if (module === '@extensions/cornerstone-dicom-rt') {
		const imported = await import('@extensions/cornerstone-dicom-rt');
		return imported.default;
	}
	if (module === '@extensions/remote-render') {
		const imported = await import('@extensions/remote-render');
		return imported.default;
	}
	if (module === '@modes/default') {
		const imported = await import('@modes/default');
		return imported.default;
	}
	if (module === '@modes/mobile') {
		const imported = await import('@modes/mobile');
		return imported.default;
	}
	if (module === '@modes/tmtv') {
		const imported = await import('@modes/tmtv');
		return imported.default;
	}
	return (await import(/* webpackIgnore: true */ module)).default;
}

// Import a list of items (modules or string names)
// @return a Promise evaluating to a list of modules
export default function importItems(modules: Array<string>) {
	return Promise.all(modules.map(loadModule));
}

export { extensions, importItems, loadModule, modes };
