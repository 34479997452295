// @ts-nocheck

import React, { useCallback, useEffect, useState } from 'react';
import getMaxDigits from '../../utils/getMaxDigits';
import Icon from '../Icon';
import IconButton from '../IconButton';
import Label from '../Label';
import './InputNumber.css';

/**
 *  React Number Input component'
 * it has two props, value and onChange
 * value is a number value
 * onChange is a function that will be called when the number input is changed
 * it can get changed by clicking on the up and down buttons
 * or by typing a number in the input
 */

const sizesClasses = {
	sm: 'w-[45px] h-[24px]',
	lg: 'w-[206px] h-[35px]'
};

const InputNumber: React.FC<{
	value: number;
	onChange: (value: number) => void;
	minValue?: number;
	maxValue?: number;
	step?: number;
	size?: 'sm' | 'lg';
	className?: string;
	inputClassname?: string;
	labelClassName?: string;
	label?: string;
	showAdjustmentArrows?: boolean;
	disabled?: boolean;
}> = ({
	value,
	onChange,
	step = 1,
	className,
	inputClassname,
	size = 'sm',
	minValue = 0,
	maxValue = 100,
	labelClassName,
	label,
	showAdjustmentArrows = true,
	disabled
}) => {
	const [numberValue, setNumberValue] = useState(value);
	const [isFocused, setIsFocused] = useState(false);

	const decimalPlaces = Number.isInteger(step) ? 0 : step.toString().split('.')[1].length;

	useEffect(() => {
		setNumberValue(value);
	}, [value]);

	const handleMinMax = useCallback((val: number) => Math.min(Math.max(val, minValue), maxValue), [maxValue, minValue]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;

		// Allow negative sign, empty string, or single decimal point for user flexibility
		if (inputValue === '-' || inputValue === '' || inputValue === '.') {
			setNumberValue(inputValue);
			return;
		}

		const number = Number(inputValue);

		// Filter out invalid inputs like 'NaN'
		if (!isNaN(number)) {
			updateValue(number);
		}
	};

	const updateValue = (val: number) => {
		const newValue = handleMinMax(val);
		setNumberValue(newValue);
		onChange(newValue);
	};

	const handleFocus = () => {
		setIsFocused(true);
	};

	const handleBlur = () => {
		setIsFocused(false);
		setNumberValue(parseFloat(numberValue).toFixed(decimalPlaces));
	};

	const increment = () => updateValue(parseFloat(numberValue) + step);
	const decrement = () => updateValue(parseFloat(numberValue) - step);

	return (
		<div className="flex flex-1 flex-col">
			{label && (
				<Label
					className={labelClassName}
					text={label}
				/>
			)}
			<div
				className={`items-centerrounded flex border border-common-light bg-primary-dark px-1 ${
					sizesClasses[size]
				} ${className || ''} `}
			>
				<div className="flex w-full">
					<input
						type="number"
						value={isFocused ? numberValue : parseFloat(numberValue).toFixed(decimalPlaces)}
						step={step}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onChange={handleChange}
						className={`input-number w-full bg-primary-dark px-1 text-[13px] text-white ${inputClassname} ${disabled && 'cursor-not-allowed'}`}
						disabled={disabled}
					/>
					{showAdjustmentArrows && !disabled && (
						<div className="up-arrowsize flex flex-col items-center justify-around">
							<ArrowButton
								onClick={increment}
								rotate
							/>
							<ArrowButton onClick={decrement} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const ArrowButton = ({ onClick, rotate = false }: { onClick: () => void; rotate?: boolean }) => (
	<IconButton
		id="arrow-icon"
		variant="text"
		color="inherit"
		size="initial"
		className={` ${rotate ? 'rotate-180 transform' : ''} bg-transparent hover:!text-primary-active`}
		onClick={onClick}
	>
		<Icon name="ui-arrow-down" />
	</IconButton>
);

export default InputNumber;
