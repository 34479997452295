import { ExtensionManager, UtilitiesModule } from '@core';

export const isFusionable = (extensionManager?: ExtensionManager) => {
	if (!extensionManager) {
		return false;
	}
	const tmtvUtils = extensionManager.getModuleEntry('@extensions/tmtv.utilityModule.utils') as UtilitiesModule;
	const { getFusionMatchingSeries } = tmtvUtils.exports;
	const { ctSeries, ptSeries } = getFusionMatchingSeries();
	const fusionable = ctSeries && ptSeries && ctSeries.length > 0 && ptSeries.length > 0;
	return fusionable;
};
