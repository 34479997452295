import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const flex = 'flex flex-row justify-between items-center';

const ListMenu = ({
	items = [],
	renderer,
	onClick = noop,
	className,
	activeOptions = true
}: {
	className?: string;
	onClick: (any) => void;
	renderer;
	items: any;
	activeOptions?: boolean;
}) => {
	const [selectedIndex, setSelectedIndex] = useState(null);

	const ListItem = ({ item, index, isSelected }) => {
		const onClickHandler = () => {
			activeOptions && setSelectedIndex(index);
			onClick({ item, selectedIndex: index });
			item.onClick?.({ ...item, index, isSelected });
		};

		return (
			<div
				className={classnames(flex, 'cursor-pointer bg-primary-main text-common-bright', index !== items.length - 1 && '')}
				onClick={onClickHandler}
				data-cy={item.id}
			>
				{renderer && renderer({ ...item, index, isSelected })}
			</div>
		);
	};

	return (
		<div
			className={classnames(
				className,
				'flex flex-col overflow-hidden rounded-md border border-common-dark bg-secondary-dark pb-1 pt-1'
			)}
		>
			{items.map((item, index) => {
				return (
					<ListItem
						key={`ListItem${index}`}
						index={index}
						isSelected={selectedIndex === index}
						item={item}
					/>
				);
			})}
		</div>
	);
};

const noop = () => undefined;

ListMenu.propTypes = {
	items: PropTypes.array.isRequired,
	renderer: PropTypes.func.isRequired,
	onClick: PropTypes.func
};

export default ListMenu;
