import React, { FC, useCallback, useMemo } from 'react';

import { BasicTable } from '@ui';
import { TableActionButton } from './TableActionButton';
import localforage from 'config/localForageConfig';
import { LocalForageKey, Preset } from 'types';
import { ServicesManager } from '@core';
import { useGetPresetData } from '@hooks';
import { UseFormReturn } from 'react-hook-form';

type PresetTableTableProps = {
	serviceManager: ServicesManager;
	formControls: UseFormReturn<Preset, any, undefined>;
};

/**
 * Bảng hiển thị danh sách preset trong popup tạo
 */
const PresetTable: FC<PresetTableTableProps> = ({ serviceManager, formControls }) => {
	const { dataTrackingService } = serviceManager.services;
	const { reset } = formControls;
	const presetData = useGetPresetData(serviceManager, false);

	/**
	 * Set value of row to form
	 */
	const onRowClick = useCallback(
		(value: Preset) => {
			reset(value);
		},
		[reset]
	);

	const onChangeIndex = useCallback(
		async (index: number, upper: boolean) => {
			const newIndex = index + (upper ? -1 : 1);
			const currentPresets = ((await localforage.getItem(LocalForageKey['Preset'])) as Preset[]) ?? [];
			const preset = currentPresets[index];
			currentPresets.splice(index, 1);
			currentPresets.splice(newIndex, 0, preset);
			await localforage.setItem(LocalForageKey['Preset'], currentPresets);
			dataTrackingService.presetDataChanged(LocalForageKey['Preset']);
		},
		[dataTrackingService]
	);

	const deletePreset = useCallback(
		async (index: number) => {
			const currentPresets = ((await localforage.getItem(LocalForageKey['Preset'])) as Preset[]) ?? [];
			currentPresets.splice(index, 1);
			await localforage.setItem(LocalForageKey['Preset'], currentPresets);
			dataTrackingService.presetDataChanged(LocalForageKey['Preset']);
		},
		[dataTrackingService]
	);

	const columns = useMemo(
		() => [
			{
				accessorKey: 'name',
				header: 'Preset Name',
				size: 30,
				cell: (cell) => <>{cell.getValue()}</>
			},
			{
				accessorKey: 'modality',
				header: 'Modality',
				size: 20,
				cell: (cell) => <>{cell.getValue()}</>
			},
			{
				accessorKey: 'ww',
				header: 'WW',
				size: 20,
				cell: (cell) => <>{cell.getValue()}</>
			},
			{
				accessorKey: 'wl',
				header: 'WL',
				size: 20,
				cell: (cell) => <>{cell.getValue()}</>
			},
			{
				header: ' ',
				accessorKey: 'actions',
				size: 40,
				cell: (cell) => {
					return (
						<TableActionButton
							numOfPreset={presetData.length}
							onChangeIndex={onChangeIndex}
							deletePreset={deletePreset}
							index={cell.row.index}
						/>
					);
				}
			}
		],
		[deletePreset, onChangeIndex, presetData.length]
	);
	return (
		<div className="grow overflow-auto">
			<BasicTable
				data={presetData}
				columns={columns}
				onRowClick={onRowClick}
			/>
		</div>
	);
};

export default PresetTable;
