import { ToolbarService } from '@core/types';

const DEFAULT_BUTTON_SECTION = 'default';

export const getSelectedButtonSection = ({
	toolbarService,
	buttonSection = DEFAULT_BUTTON_SECTION
}: {
	toolbarService: ToolbarService;
	buttonSection?: string;
}) => {
	const activeButtonSection = toolbarService.getActiveButtonSection();
	const selectedButtonSection = activeButtonSection || buttonSection;
	return selectedButtonSection;
};
